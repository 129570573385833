import React, { useState, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPencil,
  faArrowLeft,
  faEye,
  faGauge,
  faCheckCircle,
  faRibbon,
  faLevelUp,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState } from "../../../AppState";
import Layout from "../../Menu/Layout";
import { deleteLevelAPI, getLevelDetailAPI } from "../../../API/level";
import { get } from "react-scroll/modules/mixins/scroller";

function AdminLevelDetail() {
  // URL Parameters.
  const { id } = useParams();

  // Global state.
  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);

  // Component states.
  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [level, setLevel] = useState({});
  const [selectedLevelForDeletion, setSelectedLevelForDeletion] =
    useState(null);

  // Breadcrumb items
  const breadcrumbItems = {
    items: [
      {
        text: "Dashboard",
        link: "/admin/dashboard",
        isActive: false,
        icon: faGauge,
      },
      {
        text: "Levels",
        link: "/admin/levels",
        isActive: false,
        icon: faLevelUp,
      },
      { text: "Detail", link: "#", isActive: true, icon: faEye },
    ],
    mobileBackLinkItems: {
			link: "/admin/levels",
			text: "Back to Levels",
			icon: faArrowLeft,
		},
  };

  //// Event handling
  const onDeleteConfirmButtonClick = () => {
    deleteLevelAPI(
      selectedLevelForDeletion.id,
      onLevelDeleteSuccess,
      onLevelDeleteError,
      onLevelDeleteDone
    );
    setSelectedLevelForDeletion(null);
  };

  //// API
  const onLevelDetailSuccess = (response) => {
    setLevel(response);
  };

  const onLevelDetailError = (apiErr) => {
    setErrors(apiErr);
    Scroll.animateScroll.scrollToTop();
  };

  const onLevelDetailDone = () => {
    setFetching(false);
  };

  const onLevelDeleteSuccess = () => {
    setTopAlertStatus("success");
    setTopAlertMessage("Badge deleted");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    setForceURL("/admin/levels");
  };

  const onLevelDeleteError = (apiErr) => {
    setErrors(apiErr);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed to delete badge");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    Scroll.animateScroll.scrollToTop();
  };

  const onLevelDeleteDone = () => {
    setFetching(false);
  };

  //// Misc
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on mount
    setFetching(true);
    getLevelDetailAPI(
      id,
      onLevelDetailSuccess,
      onLevelDetailError,
      onLevelDetailDone
    );
  }, [id]);

  //// Component rendering
  if (forceURL) {
    return <Navigate to={forceURL} />;
  }

  return (
    <Layout breadcrumbItems={breadcrumbItems}>
      <div className="box">
        {isFetching ? (
          <PageLoadingContent displayMessage="Loading level details..." />
        ) : errors.notFound ? (
          <div className="notification is-danger">{errors.notFound}</div>
        ) : (
          <>
            <div className="columns">
              <div className="column">
                <h1 className="title is-4">
                  <FontAwesomeIcon icon={faLevelUp} />
                  &nbsp;Level
                </h1>
              </div>
              <div className="column has-text-right">
                <Link
                  to={`/admin/levels/${id}/edit`}
                  className="button is-warning is-small"
                >
                  <FontAwesomeIcon icon={faPencil} />
                  &nbsp;Edit
                </Link>
                &nbsp;
                <button
                  className="button is-danger is-small"
                  onClick={() => setSelectedLevelForDeletion(level)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                  &nbsp;Delete
                </button>
              </div>
            </div>

            <FormErrorBox errors={errors} />

            <div className="content">
              <p>
                <strong>Name:</strong> {level.name}
              </p>
              <p>
                <strong>Description:</strong> {level.description}
              </p>
              <p>
                <strong>Challenges Required:</strong> {level.numChallenges}
              </p>
              {level.thumbnailUrl ? (
                <figure className="image is-128x128">
                  <img src={level.thumbnailUrl} alt="Level Thumbnail" />
                </figure>
              ) : (
                <p>No thumbnail available.</p>
              )}
            </div>

            {/* Modal for delete confirmation */}
            {selectedLevelForDeletion && (
              <div className={`modal is-active`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                  <header className="modal-card-head">
                    <p className="modal-card-title">Confirm Deletion</p>
                    <button
                      className="delete"
                      aria-label="close"
                      onClick={() => setSelectedLevelForDeletion(null)}
                    ></button>
                  </header>
                  <section className="modal-card-body">
                    Are you sure you want to delete the level "{level.name}"?
                    This action cannot be undone.
                  </section>
                  <footer className="modal-card-foot">
                    <button
                      className="button is-danger"
                      onClick={onDeleteConfirmButtonClick}
                    >
                      Confirm
                    </button>
                    <button
                      className="button"
                      onClick={() => setSelectedLevelForDeletion(null)}
                    >
                      Cancel
                    </button>
                  </footer>
                </div>
              </div>
            )}

            <div className="columns pt-5">
              <div className="column is-half">
                <Link className="button is-fullwidth-mobile" to="/admin/levels">
                  <FontAwesomeIcon icon={faArrowLeft} />
                  &nbsp;Back to Levels
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default AdminLevelDetail;
