import React from "react";
import {useRecoilState} from "recoil";

import {USER_ROLE_MAP} from "../../../Constants/FieldOptions"
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";

const UserDetail = ({
	avatarObjectUrl,
	name,
	role,
	badgesEarned,
	badgeDetails,
	currentLevel,
	currentLevelDetails,
	totalChallengesCompletd,
}) => {
	const userRole = USER_ROLE_MAP[role];
	const defaultAvatarUrl = "static/default_user.jpg";

	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	return (
		<div className="card">
			<div className="card-content has-text-centered">
				{/* Avatar Section */}
				<figure
					className="image is-128x128 is-inline-block"
					style={{marginBottom: "1rem"}}>
					<img
						className="is-rounded"
						src={avatarObjectUrl || defaultAvatarUrl}
						alt={name}
						style={{objectFit: "cover", height: "128px", width: "128px"}}
					/>
				</figure>

				{/* Name and Role */}
				<p className="title is-4 has-text-dark">{name}</p>
				<p className="subtitle is-6 has-text-link is-italic">{userRole}</p>

				{/* Level and Progress Bar */}
				{currentUser.role === 4 && (<div>
					<h2 className="title is-6 has-text-centered has-text-primary">
						Current Level: {currentLevelDetails.name}
					</h2>
				</div>)}
			</div>
		</div>
	);
};

export default UserDetail;
