import React, { useState, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faArrowLeft,
  faEye,
  faPencil,
  faGauge,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import { getTipDetailAPI, deleteTipAPI } from "../../../API/tips";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState } from "../../../AppState";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import {
  EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
  EXERCISE_VIDEO_TYPE_VIMEO,
  EXERCISE_VIDEO_TYPE_YOUTUBE,
} from "../../../Constants/App";
import YouTubeVideo from "../../Reusable/YoutubePlayer";
import { getVimeoVideoId } from "../../Reusable/ExerciseDisplay";

function AdminTipDetail() {
  const { id } = useParams();

  const [, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [, setTopAlertStatus] = useRecoilState(topAlertStatusState);

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [tip, setTip] = useState({});
  const [selectedTipForDeletion, setSelectedTipForDeletion] = useState(null);

  const onDeleteConfirmButtonClick = () => {
    deleteTipAPI(
      selectedTipForDeletion.id,
      onTipDeleteSuccess,
      onTipDeleteError,
      onTipDeleteDone
    );
    setSelectedTipForDeletion(null);
  };

  // --- API Call Handlers --- //
  function onTipDetailSuccess(response) {
    setTip(response);
  }

  function onTipDetailError(apiErr) {
    setErrors(apiErr);
    window.scrollTo(0, 0);
  }

  function onTipDetailDone() {
    setFetching(false);
  }

  function onTipDeleteSuccess(response) {
    setTopAlertStatus("success");
    setTopAlertMessage("Tip deleted");
    setTimeout(() => setTopAlertMessage(""), 2000);
    setForceURL("/admin/tips");
  }

  function onTipDeleteError(apiErr) {
    setErrors(apiErr);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed deleting");
    setTimeout(() => setTopAlertMessage(""), 2000);
  }

  function onTipDeleteDone() {
    setFetching(false);
  }

  useEffect(() => {
    setFetching(true);
    getTipDetailAPI(id, onTipDetailSuccess, onTipDetailError, onTipDetailDone);
  }, [id]);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <>
      <div className="container">
        <section className="section">
          {/* Mobile Breadcrumbs */}
          <nav className="breadcrumb is-hidden-touch" aria-label="breadcrumbs">
            <ul>
              <li class="">
                <Link to="/admin/dashboard" aria-current="page">
                  <FontAwesomeIcon className="fas" icon={faGauge} />
                  &nbsp;Dashboard
                </Link>
              </li>
              <li>
                <Link to="/admin/tips" aria-current="page">
                  <FontAwesomeIcon icon={faLightbulb} />
                  &nbsp;Tips
                </Link>
              </li>
              <li class="is-active">
                <Link aria-current="page">
                  <FontAwesomeIcon className="fas" icon={faEye} />
                  &nbsp;Detail
                </Link>
              </li>
            </ul>
          </nav>

          <nav class="breadcrumb is-hidden-desktop" aria-label="breadcrumbs">
            <ul>
              <li class="">
                <Link to="/admin/tips" aria-current="page">
                  <FontAwesomeIcon className="fas" icon={faArrowLeft} />
                  &nbsp;Tips
                </Link>
              </li>
            </ul>
          </nav>

          <nav>
            {/* Modal for Delete Confirmation */}
            <div
              className={`modal ${
                selectedTipForDeletion !== null ? "is-active" : ""
              }`}
            >
              <div className="modal-background"></div>
              <div className="modal-card">
                <header className="modal-card-head">
                  <p className="modal-card-title">Are you sure?</p>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => setSelectedTipForDeletion(null)}
                  ></button>
                </header>
                <section className="modal-card-body">
                  You are about to delete this tip. This action cannot be
                  undone. Are you sure you would like to continue?
                </section>
                <footer className="modal-card-foot">
                  <button
                    className="button is-success"
                    onClick={onDeleteConfirmButtonClick}
                  >
                    Confirm
                  </button>
                  <button
                    className="button"
                    onClick={() => setSelectedTipForDeletion(null)}
                  >
                    Cancel
                  </button>
                </footer>
              </div>
            </div>
          </nav>

          {/* Page Content */}
          <nav className="box">
            {tip && (
              <div className="columns">
                <div className="column">
                  <p className="title is-4">
                    <FontAwesomeIcon icon={faLightbulb} />
                    &nbsp;Tip Details
                  </p>
                </div>
                <div className="column has-text-right">
                  <Link
                    to={`/admin/tips/${id}/update`}
                    className="button is-small is-warning"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faPencil} />
                    &nbsp;Edit
                  </Link>
                  &nbsp;
                  <Link
                    onClick={() => setSelectedTipForDeletion(tip)}
                    className="button is-small is-danger"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    &nbsp;Delete
                  </Link>
                </div>
              </div>
            )}
            <FormErrorBox errors={errors} />
            {isFetching ? (
              <PageLoadingContent displayMessage={"Please wait..."} />
            ) : (
              <>
                {tip && (
                  <div className="container" key={tip.id}>
                    <p className="subtitle is-6">
                      <FontAwesomeIcon icon={faEye} />
                      &nbsp;Information
                    </p>
                    <hr />

                    <DataDisplayRowText label="Name" value={tip.name} />
                    <DataDisplayRowText
                      label="Description"
                      value={tip.description}
                    />

                    {/* <img src={tip.thumbnailObjectUrl} alt="hello" srcset="" /> */}
                    {(tip.videoUrl || tip.videoObjectUrl) &&
                      (() => {
                        switch (tip.videoType) {
                          case EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE:
                            console.log("videoObjectUrl", tip.videoObjectUrl);

                            return (
                              <>
                                <video
                                  style={{ width: "100%", height: "100%" }}
                                  controls
                                  poster={tip.thumbnailObjectUrl || ""}
                                >
                                  <source
                                    src={tip.videoObjectUrl || tip.videoUrl}
                                    type="video/mp4"
                                  />
                                </video>
                              </>
                            );
                          case EXERCISE_VIDEO_TYPE_YOUTUBE:
                            return (
                              <>
                                <YouTubeVideo
                                  width={"100%"}
                                  height={"auto"}
                                  videoId={tip.videoUrl}
                                  minHeight={"50vh"}
                                />
                              </>
                            );
                          case EXERCISE_VIDEO_TYPE_VIMEO:
                            return (
                              <div className="video-container is-16by9">
                                <iframe
                                  title={tip.name}
                                  src={`https://player.vimeo.com/video/${getVimeoVideoId(
                                    tip.videoUrl
                                  )}`}
                                  // width="640"
                                  // height="360"
                                  frameborder="0"
                                  allowfullscreen
                                ></iframe>
                              </div>
                            );
                          default:
                            return null;
                        }
                      })()}

                    <div class="columns pt-5">
                      <div class="column is-half">
                        <Link
                          class="button is-fullwidth-mobile"
                          to={`/admin/exercises`}
                        >
                          <FontAwesomeIcon className="fas" icon={faArrowLeft} />
                          &nbsp;Back to Tips
                        </Link>
                      </div>
                      <div class="column is-half has-text-right">
                        <Link
                          to={`/admin/tips/${id}/update`}
                          class="button is-warning is-fullwidth-mobile"
                        >
                          <FontAwesomeIcon className="fas" icon={faPencil} />
                          &nbsp;Edit
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </nav>
        </section>
      </div>
    </>
  );
}

export default AdminTipDetail;
