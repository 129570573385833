import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faCalendarMinus,
	faCalendarPlus,
	faCheck,
	faEye,
} from "@fortawesome/free-solid-svg-icons";
import {
	PAGE_SIZE_OPTIONS,
	WORKOUT_STATUS_MAP,
} from "../../../Constants/FieldOptions";
import {currentUserState} from "../../../AppState";
import {useRecoilState} from "recoil";

function MemberListDesktop(props) {
	const {
		listData,
		setPageSize,
		pageSize,
		previousCursors,
		onPreviousClicked,
		onNextClicked,
		onChangeStatus,
	} = props;
	const [currentUser] = useRecoilState(currentUserState);

	return (
		<div className="b-table">
			<div className="table-wrapper has-mobile-cards">
				<table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
					<thead>
						<tr>
							<th>Name</th>
							<th>Users count</th>
							<th>Category</th>
							<th>Status</th>
							<th>CreatedAt</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{listData &&
							listData.results &&
							listData.results.map((datum, i) => {
								// Treat datum.users as an empty list if it's undefined or null
								const usersList = datum.users || [];

								// Count the number of users
								const userCount = usersList.length;

								// Check if the current user is part of the usersList
								const currentUserData = usersList.find(
									(u) => u.id === currentUser.id
								);

								return (
									<tr key={`desktop_${datum.id}`}>
										<td data-label="Name">{datum.name}</td>
										<td data-label="users-count">{userCount}</td>
										<td data-label="category">
											{datum.category === 2
												? "Self Update Based"
												: "Biometric based"}
										</td>

										<td data-label="Status">
											{WORKOUT_STATUS_MAP[datum.status]}
										</td>
										<td data-label="User">{datum.createdAt || " - "}</td>

										<td className="is-actions-cell">
											<div className="buttons is-right">
												<Link
													to={`/fitness-challenge/${datum.id}`}
													className="button is-small is-dark"
													type="button">
													<FontAwesomeIcon className="mdi" icon={faEye} />
													&nbsp;View
												</Link>

												{/* Handle buttons based on user completion status or if not present */}
												{currentUserData ? (
													currentUserData.completed ? (
														<button
															className="button is-small is-success"
															disabled>
															<FontAwesomeIcon className="mdi" icon={faCheck} />
															&nbsp;Completed
														</button>
													) : (
														<button
															className="button is-small is-danger"
															onClick={() => onChangeStatus(datum.id)} // Leaves the challenge
														>
															<FontAwesomeIcon
																className="mdi"
																icon={faCalendarMinus}
															/>
															&nbsp;Leave
														</button>
													)
												) : (
													<button
														className="button is-small is-success"
														onClick={() => onChangeStatus(datum.id)} // Joins the challenge
													>
														<FontAwesomeIcon
															className="mdi"
															icon={faCalendarPlus}
														/>
														&nbsp;Join
													</button>
												)}
											</div>
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>

				<div className="columns">
					<div className="column is-half">
						<span className="select">
							<select
								className={`input has-text-grey-light`}
								name="pageSize"
								onChange={(e) => setPageSize(parseInt(e.target.value))}>
								{PAGE_SIZE_OPTIONS.map((option) => (
									<option
										key={option.value}
										selected={pageSize === option.value}
										value={option.value}>
										{option.label}
									</option>
								))}
							</select>
						</span>
					</div>
					<div className="column is-half has-text-right">
						{previousCursors.length > 0 && (
							<button
								className="is-responsive button"
								onClick={onPreviousClicked}>
								Previous
							</button>
						)}
						{listData.hasNextPage && (
							<button className="is-responsive button" onClick={onNextClicked}>
								Next
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default MemberListDesktop;
