import React, {useState, useEffect} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faGauge,
	faArrowRight,
	faTable,
	faRefresh,
	faArrowUp,
	faChild,
	faRunning,
	faBolt,
	faHeart,
	faWeight,
	faMapMarkedAlt,
	faShoePrints,
	faUser,
	faTrophy,
	faDumbbell,
	faVideoCamera,
	faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import {faFreeCodeCamp} from "@fortawesome/free-brands-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../Reusable/FormErrorBox";
import {getMySummaryAPI} from "../../API/Biometric";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../AppState";
import PageLoadingContent from "../Reusable/PageLoadingContent";
import {formatDateStringWithTimezone} from "../../Helpers/timeUtility";
import AverageAndTimeComparison from "../Reusable/AverageDateAndTimeComparison";
import Layout from "../Menu/Layout";
import OnBoardingQuestionWizard from "../Reusable/Wizard/Wizard";
import {Title, SelectableOption, Card} from "../Reusable/Wizard/Questions";
import {VideoPlayer} from "./Exercise/Detail";
import {getTipsListAPI} from "../../API/tips";

function MemberDashboard() {
	////
	//// Global state.
	////

	const navigate = useNavigate();

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [datum, setDatum] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [isComingSoon, setComingSoon] = useState(true);
	const [answers, setAnswers] = useState({});
	const [forceURL, setForceURL] = useState("");
	const [hide, setHide] = useState(true);
	const defaultAvatarUrl = "static/default_user_whitebg.png";

	useEffect(() => {
		console.log(answers);
		// Perform any action here when selectedCards changes
		// For example, calling an API based on the new selection
	}, [answers]);

	////
	//// Event handling.
	////

	const handleNavigateToAccount = (e) => {
		e.preventDefault();
		navigate("/account", {state: {activeTabProp: "wearableTech"}});
	};

	const getDatum = (user) => {
		if (user !== undefined && user !== null && user !== "") {
			setFetching(true);
			setErrors({});
			getMySummaryAPI(user.id, onSummarySuccess, onSummaryError, onSummaryDone);
		}
	};

	////
	//// API.
	////

	// --- SUMMARY --- //

	function onSummarySuccess(response) {
		console.log("onSummarySuccess: Starting...");
		console.log("onSummarySuccess: response:", response);
		setDatum(response);
	}

	function onSummaryError(apiErr) {
		console.log("onSummaryError: Starting...");
		console.log(apiErr);
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onSummaryDone() {
		console.log("onSummaryDone: Starting...");
		setFetching(false);
	}

	////
	//// BREADCRUMB
	////
	const breadcrumbItems = {
		items: [{text: "Dashboard", link: "#", isActive: true, icon: faGauge}],
	};

	////
	//// Misc.
	////
	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.

			// if (currentUser && !forceURL) {
			//     if (!currentUser.onboardingCompleted && currentUser.role === 4) {
			//       setForceURL("/onboarding");
			//     }
			// }

			getDatum(currentUser);
		}

		return () => {
			mounted = false;
		};
	}, [currentUser, forceURL]);

	if (forceURL !== undefined && forceURL !== null && forceURL !== "") {
		console.log("Redirecting to:", forceURL);
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout
			breadcrumbItems={{
				items: [{text: "Dashboard", link: "#", isActive: true, icon: faGauge}],
			}}>
			<div className="box">
				<div className="columns">
					<div className="column">
						<h1 className="title is-4">
							<FontAwesomeIcon className="fas" icon={faGauge} />
							&nbsp;Dashboard
						</h1>
					</div>
					<div className="column has-text-right">
						<button
							onClick={() => getDatum(currentUser)}
							className="is-fullwidth-mobile button is-link is-small"
							type="button">
							<FontAwesomeIcon className="mdi" icon={faRefresh} />
							&nbsp;
							<span className="is-hidden-desktop is-hidden-tablet">
								Refresh
							</span>
						</button>
					</div>
				</div>

				{isFetching ? (
					<PageLoadingContent displayMessage={"Please wait..."} />
				) : (
					<>
						{/* <FormErrorBox errors={errors} /> */}

						{!hide && datum !== undefined && datum !== null && datum !== "" ? (
							<div>
								{/* Add components that should be shown only when hide is false */}
								<div className="columns">
									<div className="column">
										<div className="box">
											<div className="is-flex is-flex-direction-column-mobile is-flex-direction-row-tablet is-align-items-center">
												<div className="is-hidden-mobile">
													<figure
														className="image is-96x96"
														style={{marginRight: "1rem"}}>
														<img
															className="is-rounded"
															src={
																currentUser &&
																currentUser.avatarObjectUrl !== "" &&
																currentUser.avatarObjectUrl !== undefined
																	? currentUser.avatarObjectUrl
																	: defaultAvatarUrl
															}
															alt={currentUser.name}
															style={{
																objectFit: "cover",
																height: "96px",
																width: "96px",
																borderRadius: "50%",
																border: "12px solid #44444",
															}}
														/>
													</figure>
												</div>
												<div>
													<h5 className="is-size-3 has-text-primary has-text-weight-semibold is-size-5-mobile">
														{`Hi, ${currentUser.firstName}`}
													</h5>
													<p className="is-size-5 is-size-6-mobile">
														Here is your biometric score comparison to yesterday
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								{!hide && (
									<div className="columns">
										<div className="column">
											<div className="box is-flex is-align-items-center is-flex-direction-column">
												<span className="icon-background">
													<FontAwesomeIcon
														className="fas px-3 has-text-primary is-size-1"
														icon={faMapMarkedAlt}
													/>
												</span>
												<h5 className="mt-2 is-size-2 has-text-centered has-text-weight-semibold is-size-3-mobile">
													{datum &&
													datum.dailySummary &&
													datum.dailySummary.data &&
													datum.dailySummary.data.distance !== undefined
														? Math.round(datum.dailySummary.data.distance || 0)
														: 0}
													<span className="is-size-6">&nbsp;m</span>
												</h5>
												<p className="is-size-4 has-text-weight-semibold is-size-5-mobile">
													Distance
												</p>
											</div>
										</div>
										{/* Add more columns as necessary */}
									</div>
								)}
							</div>
						) : (
							// This section will also be hidden if hide is true
							!hide && (
								<section className="hero is-medium has-background-white-ter">
									<div className="hero-body">
										<p className="title">
											<FontAwesomeIcon className="fas" icon={faTable} />
											&nbsp;No Biometrics
										</p>
										<p className="subtitle">
											You currently have no biometrics data.{" "}
											<b>
												<Link onClick={(e) => handleNavigateToAccount(e)}>
													Click here&nbsp;
													<FontAwesomeIcon
														className="mdi"
														icon={faArrowRight}
													/>
												</Link>
											</b>
											to get started registering your wearable tech!
										</p>
									</div>
								</section>
							)
						)}

						<section className="hero mt-5 is-medium has-background-white-ter">
							<div className="is-medium has-background-white">
								<TipOfTheWeek />

								<div class="columns">
									<div class="column is-half">
										<div class="box has-text-centered hero is-medium is-dark custom-hero">
											<div class="hero-body">
												<p class="title">
													<FontAwesomeIcon
														className="fas has-text-primary"
														icon={faLeaf}
													/>
													<br />
													Nutrition Plans
												</p>
												<p class="subtitle">
													View your trainer created nutrition plans:
													<br />
													<br />
													<Link
														className="has-text-white"
														to={"/nutrition-plans"}>
														View&nbsp;
														<FontAwesomeIcon
															className="fas"
															icon={faArrowRight}
														/>
													</Link>
												</p>
											</div>
										</div>
									</div>
									<div class="column is-half">
										<div class="box has-text-centered hero is-medium is-dark custom-hero">
											<div class="hero-body">
												<p class="title">
													<FontAwesomeIcon
														className="fas has-text-primary"
														icon={faTrophy}
													/>
													<br />
													Fitness Plans
												</p>
												<p class="subtitle">
													View your trainer created fitness plans:
													<br />
													<br />
													<Link
														className="has-text-white"
														to={"/fitness-plans"}>
														View&nbsp;
														<FontAwesomeIcon
															className="fas"
															icon={faArrowRight}
														/>
													</Link>
												</p>
											</div>
										</div>
									</div>
								</div>

								<div class="columns">
									<div class="column">
										<div class="box has-text-centered hero is-medium is-dark custom-hero">
											<div class="hero-body">
												<p class="title">
													<FontAwesomeIcon
														className="fas has-text-primary"
														icon={faDumbbell}
													/>
													<br />
													Exercises
												</p>
												<p class="subtitle">
													View all the exercises to help you at the gym:
													<br />
													<br />
													<Link className="has-text-white" to={"/exercises"}>
														View&nbsp;
														<FontAwesomeIcon
															className="fas"
															icon={faArrowRight}
														/>
													</Link>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</>
				)}
			</div>
		</Layout>
	);
}

export default MemberDashboard;

function TipOfTheWeek() {
	// Global State
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	// Component States
	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10); // Pagination
	const [previousCursors, setPreviousCursors] = useState([]); // Pagination
	const [nextCursor, setNextCursor] = useState(""); // Pagination
	const [currentCursor, setCurrentCursor] = useState(""); // Pagination
	const [showFilter, setShowFilter] = useState(false); // Filtering + Searching
	const [sortField, setSortField] = useState("created"); // Sorting
	const [temporarySearchText, setTemporarySearchText] = useState(""); // Searching - The search field value as your writes their query.
	const [actualSearchText, setActualSearchText] = useState(""); // Searching - The actual search query value to submit to the API.;
	const [status, setStatus] = useState("");
	const [videoType, setVideoType] = useState("");

	////
	//// API.
	////

	const fetchList = (cur, limit, keywords, cat, st, vt) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit); // Pagination
		params.set("sort_field", "created_at"); // Sorting
		params.set("sort_order", -1); // Sorting - descending, meaning most recent start date to oldest start date.

		if (cur !== "") {
			// Pagination
			params.set("cursor", cur);
		}

		// Filtering
		if (keywords !== undefined && keywords !== null && keywords !== "") {
			// Searhcing
			params.set("search", keywords);
		}
		if (cat !== undefined && cat !== null && cat !== "") {
			params.set("category", cat);
		}
		if (st !== undefined && st !== null && st !== "") {
			params.set("status", st);
		}
		if (vt !== undefined && vt !== null && vt !== "") {
			params.set("video_type", vt);
		}

		getTipsListAPI(
			params,
			onPreviousTipsListSuccess,
			onPreviousTipsListError,
			onPreviousTipsListDone
		);
	};

	const onPreviousTipsListSuccess = (response) => {
		console.log("onPreviousTipsListSuccess: Starting...");
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor); // For pagination purposes.
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	};

	const onPreviousTipsListError = (apiErr) => {
		console.log("onPreviousTipsListError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	};

	const onPreviousTipsListDone = () => {
		console.log("onPreviousTipsListDone: Starting...");
		setFetching(false);
	};

	// Fetch Data on Component Mount or Change in Filters
	useEffect(() => {
		let mounted = true;
		if (mounted) {
			window.scrollTo(0, 0);
			fetchList(currentCursor, pageSize, actualSearchText, status, videoType);
		}
		return () => {
			mounted = false;
		};
	}, []);

	return (
		<div className="box has-background-dark p-5">
			<div className="has-text-centered">
				{/* Tip of the Week Title */}
				{listData &&
				listData.results &&
				(listData.results.length > 0 || previousCursors.length > 0) ? (
					<>
						<p className="title has-text-primary my-5 is-size-3">
							{listData.results[0]?.name || "No title available"}
						</p>

						{/* Video Player */}
						<div className="video-wrapper mb-4">
							{listData.results[0] ? (
								<VideoPlayer
									videoType={listData.results[0].videoType}
									videoUrl={listData.results[0].videoUrl}
									videoObjectUrl={listData.results[0].videoObjectUrl}
									thumbnailType={listData.results[0].thumbnailType}
									thumbnailUrl={listData.results[0].thumbnailUrl}
									thumbnailObjectUrl={listData.results[0].thumbnailObjectUrl}
									tagsHidden={true}
								/>
							) : (
								<p className="has-text-white">No video available</p>
							)}
						</div>

						{/* Description */}
						<p className="subtitle has-text-white is-size-5 mb-5">
							{listData.results[0]?.description || "No description available"}
						</p>
					</>
				) : (
					<p className="has-text-white">No tips available for this week.</p>
				)}
				{/* Link to View Previous Week's Tip */}
				<p className="previous-week-link">
					<Link to="/previous-week-tips" className="has-text-primary is-size-6">
						View the Previous Week's Tip
					</Link>
				</p>
			</div>
		</div>
	);
}
