import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import {
  PAGE_SIZE_OPTIONS,
  TIPS_STATUS_MAP,
  // TIPS_STATUS_MAP,
  // TIPS_CATEGORY_MAP,
  // TIPS_TYPE_MAP,
} from "../../../Constants/FieldOptions";

/*
Display for both tablet and mobile.
*/
function AdminTipsListMobile(props) {
  const {
    listData,
    setPageSize,
    pageSize,
    previousCursors,
    onPreviousClicked,
    onNextClicked,
    onSelectTipForDeletion,
  } = props;

  return (
    <>
      {listData &&
        listData.results &&
        listData.results.map((datum) => {
          return (
            <div className="pb-6  box-sahdow" key={`mobile_tablet_${datum.id}`}>
              <strong>Name:</strong>&nbsp;{datum.name}
              <br />
              <br />
              <strong>Status:</strong>&nbsp;
              {TIPS_STATUS_MAP[datum.status]}
              <br />
              <br />
              <p style={{ wordWrap: "break-word" }}>
                <strong>Description:</strong>&nbsp;
                {datum.description.length > 100
                  ? `${datum.description.slice(0, 100)}...`
                  : datum.description}
              </p>
              <br />
              <br />
              {/* Tablet only */}
              <div className="is-hidden-mobile">
                <div className="buttons is-right">
                  <Link
                    to={`/admin/tips/${datum.id}`}
                    className="button is-small is-dark"
                    type="button"
                  >
                    <FontAwesomeIcon className="mdi" icon={faEye} />
                    &nbsp;View
                  </Link>
                  <button
                    onClick={(e) => onSelectTipForDeletion(e, datum)}
                    className="button is-small is-danger"
                    type="button"
                  >
                    <FontAwesomeIcon className="mdi" icon={faTrashCan} />
                    &nbsp;Delete
                  </button>
                </div>
              </div>
              {/* Mobile only */}
              <div className="is-hidden-tablet">
                <div className="columns is-mobile">
                  <div className="column">
                    <Link
                      to={`/admin/tips/${datum.id}`}
                      className="button is-small is-dark is-fullwidth"
                      type="button"
                    >
                      <FontAwesomeIcon className="mdi" icon={faEye} />
                      &nbsp;View
                    </Link>
                  </div>
                  <div className="column">
                    <button
                      onClick={(e) => onSelectTipForDeletion(e, datum)}
                      className="button is-small is-danger is-fullwidth"
                      type="button"
                    >
                      <FontAwesomeIcon className="mdi" icon={faTrashCan} />
                      &nbsp;Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      <div className="columns pt-4 is-mobile">
        <div className="column is-half">
          <span className="select">
            <select
              className={`input has-text-grey-light`}
              name="pageSize"
              onChange={(e) => setPageSize(parseInt(e.target.value))}
            >
              {PAGE_SIZE_OPTIONS.map((option) => {
                return (
                  <option
                    key={option.value}
                    selected={pageSize === option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                );
              })}
            </select>
          </span>
        </div>
        <div className="column is-half has-text-right">
          {previousCursors.length > 0 && (
            <button className="button" onClick={onPreviousClicked}>
              Previous
            </button>
          )}
          {listData.hasNextPage && (
            <button className="button" onClick={onNextClicked}>
              Next
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminTipsListMobile;
