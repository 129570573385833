import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLink} from "@fortawesome/free-solid-svg-icons";
import {useLocation} from "react-router-dom";

const Footer = () => {
	const [currentDateTime, setCurrentDateTime] = useState("");
	const [userLocation, setUserLocation] = useState("Ontario"); // Default to "Ontario"

	const location = useLocation();
	const isindexPage = location.pathname === "/";

	useEffect(() => {
		// Function to update current date and time
		const updateDateTime = () => {
			const now = new Date();
			const options = {
				year: "numeric",
				month: "long",
				day: "numeric",
				hour: "numeric",
				minute: "numeric",
				second: "numeric",
				hour12: true,
			};
			const formattedDateTime = now.toLocaleString("en-US", options);
			setCurrentDateTime(formattedDateTime);
		};

		// Get user's current location
		const updateUserLocation = () => {
			if ("geolocation" in navigator) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						const {latitude, longitude} = position.coords;

						// Use reverse geocoding to get the location name (city, state)
						fetch(
							`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
						)
							.then((response) => response.json())
							.then((data) => {
								const locationName = data.address?.state || "Ontario"; // Use state or fallback to Ontario
								console.log("Current Location is:", data);
								setUserLocation(locationName);
							})
							.catch((error) => {
								console.error("Error fetching location:", error);
								setUserLocation("Ontario"); // Fallback in case of error
							});
					},
					(error) => {
						console.error("Geolocation error:", error);
						setUserLocation("Ontario"); // Fallback if user denies location access
					}
				);
			} else {
				setUserLocation("Ontario"); // Fallback if geolocation is not supported
			}
		};

		// Update the current date and time initially and then every minute
		updateDateTime();
		updateUserLocation();
		const interval = setInterval(updateDateTime, 60000); // 60000ms = 1 minute

		// Clean up the interval on component unmount
		return () => clearInterval(interval);
	}, []);

	return (
		<footer className="footer-main px-5 py-4" role="contentinfo">
			<div className="container is-fluid">
				<div className="columns is-flex-wrap-wrap">
					<div className="column is-half-desktop">
						<div className="copy-right">
							<p className="is-size-7 has-text-white" id="currentDateTime">
								{currentDateTime} in {userLocation}
								<br />(<cite>
									Data by OpenStreetMap, licensed under ODbL
								</cite>) <br />
								Developed by
								<a
									href="https://bcinnovationlabs.com"
									rel="noopener noreferrer"
									target="_blank">
									&nbsp;BCI Innovation Labs&nbsp;
									<FontAwesomeIcon className="fas" icon={faExternalLink} />
								</a>
							</p>
						</div>
					</div>
					<div className="column is-half-desktop">
						<div className="is-flex-desktop is-justify-content-end">
							<div className="">
								<p className="is-size-7 mr-5 has-text-white" id="siteInfo">
									<a
										href="https://bp8.ca/privacy-policy/"
										rel="noopener noreferrer"
										target="_blank">
										Privacy Policy&nbsp;
										<FontAwesomeIcon className="fas" icon={faExternalLink} />
									</a>
									&nbsp;©2023&nbsp; Fitness Training <br />
									201 King Street, London, Ontario N6A 1C9
								</p>
							</div>
							<div style={{maxWidth: "80px"}}>
								<a href="#" aria-label="F45 Training Logo">
									<img
										src={isindexPage ? "/static/logo.png" : "/static/logo.png"}
										alt="BP8 Fitness Logo"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
