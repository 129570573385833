import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faRibbon,
  faPlus,
  faGauge,
  faRefresh,
  faTable,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import { getBadgesListAPI, deleteBadgeAPI } from "../../../API/badge";
import {
  topAlertMessageState,
  topAlertStatusState,
  currentUserState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import AdminBadgeListDesktop from "./ListDesktop";
import AdminBadgeListMobile from "./ListMobile";
import Layout from "../../Menu/Layout";

function AdminBadgeList() {
  // Global state
  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  // Component states
  const [errors, setErrors] = useState({});
  const [listData, setListData] = useState(null);
  const [selectedBadgeForDeletion, setSelectedBadgeForDeletion] =
    useState(null);
  const [isFetching, setIsFetching] = useState(false);

  // Pagination
  const [pageSize, setPageSize] = useState(10);
  const [previousCursors, setPreviousCursors] = useState([]);
  const [nextCursor, setNextCursor] = useState(null);
  const [currentCursor, setCurrentCursor] = useState("");

  const breadcrumbItems = {
    items: [
      {
        text: "Dashboard",
        link: "/admin/dashboard",
        isActive: false,
        icon: faGauge,
      },
      { text: "Badges", link: "/admin/badges", isActive: true, icon: faRibbon },
    ],
  };

  // API Calls
  const fetchBadges = (cursor, limit) => {
    setIsFetching(true);
    setErrors({});

    let params = new Map();
    params.set("page_size", limit);

    if (cursor) params.set("cursor", cursor);

    getBadgesListAPI(
      params,
      onBadgesListSuccess,
      onBadgesListError,
      onBadgesListDone
    );
  };

  const onBadgesListSuccess = (response) => {
    setListData(response);
    if (response.hasNextPage) {
      setNextCursor(response.nextCursor);
    } else {
      setNextCursor(null);
    }
  };

  const onBadgesListError = (apiErr) => {
    setErrors(apiErr);
  };

  const onBadgesListDone = () => {
    setIsFetching(false);
  };

  const onBadgeDeleteSuccess = (response) => {
    setTopAlertMessage("Badge deleted successfully.");
    setTopAlertStatus("success");
    fetchBadges(currentCursor, pageSize); // Fetch updated list after deletion
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
  };

  const onBadgeDeleteError = (apiErr) => {
    setErrors(apiErr);
    setTopAlertMessage("Failed to delete badge.");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
  };

  const onDeleteConfirmButtonClick = () => {
    deleteBadgeAPI(
      selectedBadgeForDeletion.id,
      onBadgeDeleteSuccess,
      onBadgeDeleteError,
      () => setSelectedBadgeForDeletion(null)
    );
  };

  // Pagination event handlers
  const onNextClicked = () => {
    const newPrevCursors = [...previousCursors, currentCursor];
    setPreviousCursors(newPrevCursors);
    setCurrentCursor(nextCursor);
  };

  const onPreviousClicked = () => {
    const prevCursor = previousCursors.pop();
    setPreviousCursors([...previousCursors]);
    setCurrentCursor(prevCursor);
  };

  // Initial data fetch on component mount
  useEffect(() => {
    fetchBadges(currentCursor, pageSize);
  }, [currentCursor, pageSize]);

  // Component rendering
  return (
    <Layout breadcrumbItems={breadcrumbItems}>
      {/* Modal for Deletion Confirmation */}
      {selectedBadgeForDeletion && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Are you sure?</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => setSelectedBadgeForDeletion(null)}
              ></button>
            </header>
            <section className="modal-card-body">
              You are about to delete this badge. This action cannot be undone.
              Are you sure you want to proceed?
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-success"
                onClick={onDeleteConfirmButtonClick}
              >
                Confirm
              </button>
              <button
                className="button"
                onClick={() => setSelectedBadgeForDeletion(null)}
              >
                Cancel
              </button>
            </footer>
          </div>
        </div>
      )}

      {/* Page Content */}
      <div className="box">
        <div className="columns">
          <div className="column">
            <h1 className="title is-4">
              <FontAwesomeIcon icon={faRibbon} />
              &nbsp;Badges
            </h1>
          </div>
          <div className="column has-text-right">
            <button
              onClick={() => fetchBadges(currentCursor, pageSize)}
              className="button is-link is-small"
            >
              <FontAwesomeIcon icon={faRefresh} />
              &nbsp;Refresh
            </button>
            &nbsp;
            <Link to="/admin/badges/add" className="button is-success is-small">
              <FontAwesomeIcon icon={faPlus} />
              &nbsp;New Badge
            </Link>
          </div>
        </div>

        {/* Badge List */}
        {isFetching ? (
          <PageLoadingContent displayMessage="Loading badges..." />
        ) : (
          <>
            <FormErrorBox errors={errors} />
            {listData &&
            listData.results &&
            (listData.results.length > 0 || previousCursors.length > 0) ? (
              <div>
                {/*
                  ##################################################################
                  EVERYTHING INSIDE HERE WILL ONLY BE DISPLAYED ON A DESKTOP SCREEN.
                  ##################################################################
              */}
                <div class="is-hidden-touch">
                  <AdminBadgeListDesktop
                    listData={listData}
                    setPageSize={setPageSize}
                    pageSize={pageSize}
                    previousCursors={previousCursors}
                    onPreviousClicked={onPreviousClicked}
                    onNextClicked={onNextClicked}
                    setSelectedBadgeForDeletion={setSelectedBadgeForDeletion}
                  />
                </div>

                {/*
                  ###########################################################################
                  EVERYTHING INSIDE HERE WILL ONLY BE DISPLAYED ON A TABLET OR MOBILE SCREEN.
                  ###########################################################################
              */}
                <div class="is-fullwidth is-hidden-desktop">
                  <AdminBadgeListMobile
                    listData={listData}
                    setPageSize={setPageSize}
                    pageSize={pageSize}
                    previousCursors={previousCursors}
                    onPreviousClicked={onPreviousClicked}
                    onNextClicked={onNextClicked}
                    setSelectedBadgeForDeletion={setSelectedBadgeForDeletion}
                  />
                </div>
              </div>
            ) : (
              <section className="hero is-medium has-background-white-ter">
                <div className="hero-body">
                  <p className="title">
                    <FontAwesomeIcon icon={faTable} />
                    &nbsp;No Badges Found
                  </p>
                  <p className="subtitle">
                    No badges available.{" "}
                    <Link to="/admin/badges/add">
                      <b>Create a new badge</b>
                    </Link>{" "}
                    to get started.
                  </p>
                </div>
              </section>
            )}
          </>
        )}
      </div>

      {/* Pagination */}
      {listData && listData.results.length > 0 && (
        <div className="columns">
          <div className="column is-half">
            {previousCursors.length > 0 && (
              <button className="button" onClick={onPreviousClicked}>
                Previous
              </button>
            )}
          </div>
          <div className="column is-half has-text-right">
            {nextCursor && (
              <button className="button" onClick={onNextClicked}>
                Next
              </button>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}

export default AdminBadgeList;
