import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEye,
  faPencil,
  faTrashCan,
  faPlus,
  faGauge,
  faArrowRight,
  faTable,
  faRefresh,
  faLevelUp,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import {
  topAlertMessageState,
  topAlertStatusState,
  currentUserState,
} from "../../../AppState";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Layout from "../../Menu/Layout";
import AdminLevelListMobile from "./ListMobile";
import AdminLevelListDesktop from "./ListDesktop";
import { deleteLevelAPI, getLevelsListAPI } from "../../../API/level";

function AdminLevelList() {
  // Global state
  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  // Component states
  const [errors, setErrors] = useState({});
  const [listData, setListData] = useState(null);
  const [selectedLevelForDeletion, setSelectedLevelForDeletion] =
    useState(null);
  const [isFetching, setIsFetching] = useState(false);

  // Pagination
  const [pageSize, setPageSize] = useState(10);
  const [previousCursors, setPreviousCursors] = useState([]);
  const [nextCursor, setNextCursor] = useState(null);
  const [currentCursor, setCurrentCursor] = useState("");

  // Breadcrumb items
  const breadcrumbItems = {
    items: [
      {
        text: "Dashboard",
        link: "/admin/dashboard",
        isActive: false,
        icon: faGauge,
      },
      { text: "Levels", link: "#", isActive: true, icon: faLevelUp },
    ],
    mobileBackLinkItems: {
			link: "/admin/dashboard",
			text: "Back to Dashboard",
			icon: faArrowLeft,
		},
  };

  // API Calls
  const fetchLevels = (cursor, limit) => {
    setIsFetching(true);
    setErrors({});

    let params = new Map();
    params.set("page_size", limit);

    if (cursor) params.set("cursor", cursor);

    getLevelsListAPI(
      params,
      onLevelsListSuccess,
      onLevelsListError,
      onLevelsListDone
    );
  };

  const onLevelsListSuccess = (response) => {
    setListData(response);
    if (response.hasNextPage) {
      setNextCursor(response.nextCursor);
    } else {
      setNextCursor(null);
    }
  };

  const onLevelsListError = (apiErr) => {
    setErrors(apiErr);
  };

  const onLevelsListDone = () => {
    setIsFetching(false);
  };

  const onLevelDeleteSuccess = (response) => {
    setTopAlertMessage("Level deleted successfully.");
    setTopAlertStatus("success");
    fetchLevels(currentCursor, pageSize); // Fetch updated list after deletion
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
  };

  const onLevelDeleteError = (apiErr) => {
    setErrors(apiErr);
    setTopAlertMessage("Failed to delete badge.");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
  };

  const onDeleteConfirmButtonClick = () => {
    deleteLevelAPI(
      selectedLevelForDeletion.id,
      onLevelDeleteSuccess,
      onLevelDeleteError,
      () => setSelectedLevelForDeletion(null)
    );
  };

  // Pagination event handlers
  const onNextClicked = () => {
    const newPrevCursors = [...previousCursors, currentCursor];
    setPreviousCursors(newPrevCursors);
    setCurrentCursor(nextCursor);
  };

  const onPreviousClicked = () => {
    const prevCursor = previousCursors.pop();
    setPreviousCursors([...previousCursors]);
    setCurrentCursor(prevCursor);
  };

  // Initial data fetch on component mount
  useEffect(() => {
    fetchLevels(currentCursor, pageSize);
  }, [currentCursor, pageSize]);

  return (
    <Layout breadcrumbItems={breadcrumbItems}>
      {/* Modal for Deletion Confirmation */}
      <div className={`modal ${selectedLevelForDeletion ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Are you sure?</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setSelectedLevelForDeletion(null)}
            ></button>
          </header>
          <section className="modal-card-body">
            You are about to delete this level. This action cannot be undone.
            Are you sure you want to proceed?
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-success"
              onClick={onDeleteConfirmButtonClick}
            >
              Confirm
            </button>
            <button
              className="button"
              onClick={() => setSelectedLevelForDeletion(null)}
            >
              Cancel
            </button>
          </footer>
        </div>
      </div>

      {/* Page Content */}
      <div className="box">
        <div className="columns">
          <div className="column">
            <h1 className="title is-4">
              <FontAwesomeIcon icon={faTable} />
              &nbsp;Levels
            </h1>
          </div>
          <div className="column has-text-right">
            <button
              onClick={() => fetchLevels(currentCursor, pageSize)}
              className="button is-link is-small"
            >
              <FontAwesomeIcon icon={faRefresh} />
              &nbsp;Refresh
            </button>
            &nbsp;
            <Link to="/admin/levels/add" className="button is-success is-small">
              <FontAwesomeIcon icon={faPlus} />
              &nbsp;New Level
            </Link>
          </div>
        </div>

        {/* List of Levels */}
        {isFetching ? (
          <PageLoadingContent displayMessage="Loading levels..." />
        ) : (
          <>
            <FormErrorBox errors={errors} />
            {listData &&
            listData.results &&
            (listData.results.length > 0 || previousCursors.length > 0) ? (
              <div>
                {/*
                  ##################################################################
                  EVERYTHING INSIDE HERE WILL ONLY BE DISPLAYED ON A DESKTOP SCREEN.
                  ##################################################################
              */}
                <div class="is-hidden-touch">
                  <AdminLevelListDesktop
                    listData={listData}
                    setPageSize={setPageSize}
                    pageSize={pageSize}
                    previousCursors={previousCursors}
                    onPreviousClicked={onPreviousClicked}
                    onNextClicked={onNextClicked}
                    setSelectedLevelForDeletion={setSelectedLevelForDeletion}
                  />
                </div>

                {/*
                  ###########################################################################
                  EVERYTHING INSIDE HERE WILL ONLY BE DISPLAYED ON A TABLET OR MOBILE SCREEN.
                  ###########################################################################
              */}
                <div class="is-fullwidth is-hidden-desktop">
                  <AdminLevelListMobile
                    listData={listData}
                    setPageSize={setPageSize}
                    pageSize={pageSize}
                    previousCursors={previousCursors}
                    onPreviousClicked={onPreviousClicked}
                    onNextClicked={onNextClicked}
                    setSelectedLevelForDeletion={setSelectedLevelForDeletion}
                  />
                </div>
              </div>
            ) : (
              <section className="hero is-medium has-background-white-ter">
                <div className="hero-body">
                  <p className="title">
                    <FontAwesomeIcon icon={faTable} />
                    &nbsp;No Badges Found
                  </p>
                  <p className="subtitle">
                    No badges available.{" "}
                    <Link to="/admin/levels/add">
                      <b>Create a new Level</b>
                    </Link>{" "}
                    to get started.
                  </p>
                </div>
              </section>
            )}
          </>
        )}
      </div>

      {/* Pagination */}
      {listData && listData.results.length > 0 && (
        <div className="columns">
          <div className="column is-half">
            {previousCursors.length > 0 && (
              <button className="button" onClick={onPreviousClicked}>
                Previous
              </button>
            )}
          </div>
          <div className="column is-half has-text-right">
            {nextCursor && (
              <button className="button" onClick={onNextClicked}>
                Next
              </button>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}

export default AdminLevelList;
