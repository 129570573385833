import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState, useRecoilValue} from "recoil";
import {
	onHamburgerClickedState,
	currentUserState,
	topAlertStatusState,
	topAlertMessageState,
	accountState,
} from "../../AppState";
import {useAccountQuery} from "../../Hooks/useAccountQuery"; // Hook from React Query
import Scroll from "react-scroll";
import ProgressBar from "../Reusable/ProgressBar";

function Topbar() {
	// Global state via Recoil
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
	const [onHamburgerClicked, setOnHamburgerClicked] = useRecoilState(
		onHamburgerClickedState
	);
	// Component states
	const [showLogoutWarning, setShowLogoutWarning] = useState(false);

	// API Data with React Query
	const {data: accountData, isLoading, isError, error} = useAccountQuery(); // React Query hook for account details

	const location = useLocation();

	// If account data is loading or unavailable, show nothing
	//   if (isLoading || !accountData) return null;

	// Safeguard for undefined accountData
	if (isLoading || !accountData) {
		return null; // or you could return a loading spinner
	}

	// Progress bar details
	const {
		currentLevelDetails,
		currentLevel,
		totalChallengesCompleted,
		avatarObjectUrl,
		name,
	} = accountData;

	const progressPercentage =
		(currentLevel?.challengesCompleted / currentLevelDetails?.numChallenges) *
		100;

	// Conditional rendering for certain routes
	const ignorePathsArr = [
		"/",
		"/register",
		"/login",
		"/logout",
		"/forgot-password",
	];
	if (ignorePathsArr.includes(location.pathname) || currentUser === null) {
		return null;
	}

	const defaultAvatarUrl = "/static/default_user.jpg";

	return (
		<div>
			<nav
				className="navbar has-background-dark"
				role="navigation"
				aria-label="main navigation">
				<div className="navbar-brand">
					{currentUser && (
						<Link
							to={
								currentUser.role === 2 || currentUser.role === 3
									? "admin/dashboard"
									: "/dashboard"
							}>
							<div className="profile-picture-container">
								<img
									src="/static/logo.png"
									alt="Logo"
									className="profile-picture"
								/>
							</div>
						</Link>
					)}
					{/* Burger icon for mobile */}
					<button
						className={`navbar-burger has-text-white ${
							onHamburgerClicked ? "is-active" : ""
						}`}
						aria-label="menu"
						aria-expanded={onHamburgerClicked}
						onClick={() => setOnHamburgerClicked(!onHamburgerClicked)}>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</button>
				</div>

				{/* Navbar Menu */}
				<div
					className={`navbar-menu is-hidden-touch ${
						onHamburgerClicked ? "is-active" : ""
					}`}>
					<div className="navbar-start">
						<div className="navbar-item">
							<FontAwesomeIcon
								onClick={() => setOnHamburgerClicked(!onHamburgerClicked)}
								className="fas has-text-white"
								icon={faBars}
							/>
						</div>
					</div>

					<div className="navbar-end">
						{currentUser.role === 4 && (
							<div className="navbar-item">
								<ProgressBar
									currentLevel={currentLevel}
									currentLevelDetails={currentLevelDetails}
								/>
							</div>
						)}

						<div className="navbar-item">
							<figure className="image is-32x32 is-inline-block">
								<img
									className="is-rounded"
									src={avatarObjectUrl || defaultAvatarUrl}
									alt={name}
									style={{objectFit: "cover", height: "32px", width: "32px"}}
								/>
							</figure>
						</div>

						<div className="navbar-item">
							<button
								className="button is-primary is-small has-text-white"
								onClick={() => setShowLogoutWarning(true)}>
								Logout
							</button>
						</div>
					</div>
				</div>
			</nav>

			{/* Logout Modal */}
			{showLogoutWarning && (
				<div className="modal is-active">
					<div className="modal-background"></div>
					<div className="modal-card">
						<header className="modal-card-head">
							<p className="modal-card-title">Are you sure?</p>
							<button
								className="delete"
								onClick={() => setShowLogoutWarning(false)}></button>
						</header>
						<section className="modal-card-body">
							Are you sure you want to log out? You will need to log in again
							next time.
						</section>
						<footer className="modal-card-foot">
							<Link to="/logout" className="button is-danger">
								Yes, Log Out
							</Link>
							<button
								className="button"
								onClick={() => setShowLogoutWarning(false)}>
								Cancel
							</button>
						</footer>
					</div>
				</div>
			)}
		</div>
	);
}

export default Topbar;
