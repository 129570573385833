import {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faArrowLeft,
	faGauge,
	faPencil,
	faEye,
	faBolt,
	faPencilRuler,
	faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";

import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	fitnessChallengeDatumState,
} from "../../../AppState";
import {
	deletefitnessChallengeAPI,
	getfitnessChallengeDetailAPI,
} from "../../../API/FitnessChallenge";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import {
	BADGE_CRITERIA_OPTIONS,
	BADGE_STATUS_MAP,
} from "../../../Constants/FieldOptions";
import Layout from "../../Menu/Layout";

function AdminFitnessChallengeDetail() {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [fitnessChallengeDatum, setFitnessChallengedatum] = useRecoilState(
		fitnessChallengeDatumState
	);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [selectedChallengeForDeletion, setselectedChallengeForDeletion] =
		useState(null);

	// Breadcrumb items
	const breadcrumbItems = {
		items: [
			{
				text: "Dashboard",
				link: "/admin/dashboard",
				isActive: false,
				icon: faGauge,
			},
			{
				text: "Challenges",
				link: "/admin/fitness-challenge",
				isActive: false,
				icon: faBolt,
			},
			{text: "Detail", link: "#", isActive: true, icon: faEye},
		],
		mobileBackLinkItems: {
			link: "/admin/fitness-challenge",
			text: "Back to Challenges",
			icon: faArrowLeft,
		},
	};

	////
	//// API.
	////

	////
	//// Event handling.
	////

	// --- Detail --- //

	function onVideoCollectionDetailSuccess(response) {
		setDatum(response);
		setFitnessChallengedatum(response);
	}

	function onVideoCollectionDetailError(apiErr) {
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onVideoCollectionDetailDone() {
		setFetching(false);
	}

	// --- Delete --- //

	function ondeleteSuccess(response) {
		// Update notification.
		setTopAlertStatus("success");
		setTopAlertMessage("challenge deleted");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Redirect back to the members page.
		setForceURL("/admin/fitness-challenge");
	}

	function ondeleteError(apiErr) {
		setErrors(apiErr);

		// Update notification.
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onDeleteDone() {
		setFetching(false);
	}

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.

			setFetching(true);
			getfitnessChallengeDetailAPI(
				id,
				onVideoCollectionDetailSuccess,
				onVideoCollectionDetailError,
				onVideoCollectionDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);
	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems}>
			{/* Page */}
			<nav class="box">
				{/* Title + Options */}
				{datum && (
					<div class="columns">
						<div class="column">
							<p class="title is-4">
								<FontAwesomeIcon className="fas" icon={faBolt} />
								&nbsp;Challenges
							</p>
						</div>
						<div class="column has-text-right">
							<Link
								to={`/admin/fitness-challenge/${id}/update`}
								class="button is-small is-responsive is-warning is-fullwidth-mobile"
								type="button">
								<FontAwesomeIcon className="mdi" icon={faPencil} />
								&nbsp;Edit
							</Link>
						</div>
					</div>
				)}

				{/* Tab Navigation */}
				<div class="tabs is-medium is-size-6-mobile">
					<ul>
						<li class="is-active">
							<Link>
								<strong>Detail</strong>
							</Link>
						</li>
						<li>
							<Link to={`/admin/fitness-challenge/${id}/leaderboard`}>
								<strong>Leaderboard</strong>
							</Link>
						</li>
						{datum.criteria === 2 && (
							<li>
								<Link to={`/admin/fitness-challenge/${id}/assign-badge`}>
									<strong>Assign Badges</strong>
								</Link>
							</li>
						)}
					</ul>
				</div>

				{/* <p class="pb-4">Please fill out all the required fields before submitting this form.</p> */}

				{isFetching ? (
					<PageLoadingContent displayMessage={"Please wait..."} />
				) : (
					<>
						<FormErrorBox errors={errors} />
						<div className="columns">
							<div className="column ">
								<h2 className="title is-4 mb-3">{datum?.name}</h2>
								{/* Challenge Info */}
								<DataDisplayRowText
									label="Challenge Overview"
									value={datum.description}
								/>
								<DataDisplayRowText
									label="Duration"
									value={`${datum.durationInWeeks} weeks`}
								/>
								<DataDisplayRowText
									label="Start Date"
									value={datum.startTime}
									type="datetime"
								/>
								<DataDisplayRowText
									label="Maximum Points Required to complete the challenge"
									value={datum.maxPoints}
								/>
								<DataDisplayRowText
									label="Challenge Type"
									value={
										datum.category === 2
											? "Self Update Based"
											: "Biometric Based"
									}
								/>

								<DataDisplayRowText
									label="Criteria"
									value={BADGE_STATUS_MAP[datum.criteria]}
								/>

								{/* Badge Info */}
								{/* Badge Info */}
								{datum.earnsBadge && (
									<DataDisplayRowText
										label="Badge Awarded"
										value={datum.badgeName}
									/>
								)}

								{/* Rules Section */}
								<p className="subtitle is-6 mt-3">
									<FontAwesomeIcon className="fas" icon={faPencilRuler} />
									&nbsp;Challenge Rules
								</p>
								{datum?.rules?.map((rule, index) => (
									<div className="box" key={index}>
										<h3 className="title is-5 mb-3">
											Rule {index + 1}: {rule.name}
										</h3>
										<DataDisplayRowText
											label="Details"
											value={rule.description}
										/>
									</div>
								))}

								{/* Members Section */}
								<p className="subtitle is-6 mt-3">
									<FontAwesomeIcon className="fas" icon={faUsers} />
									&nbsp;Participants
								</p>
								<div className="content">
									<ul>
										{datum?.users && datum?.users.length > 0 ? (
											datum.users.map((user, index) => (
												<li key={index}>
													{user.name} -{" "}
													{user.completed ? (
														<button className="button is-small is-responsive is-success">
															Completed
														</button>
													) : (
														<button className="button is-small is-responsive is-warning">
															In Progress
														</button>
													)}
												</li>
											))
										) : (
											<li>No participants have joined yet.</li> // Fallback message
										)}
									</ul>
								</div>
							</div>
						</div>
					</>
				)}
			</nav>
		</Layout>
	);
}

export default AdminFitnessChallengeDetail;
