import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBillWave,
  faImage,
  faVideo,
  faRepeat,
  faTasks,
  faTachometer,
  faPlus,
  faArrowLeft,
  faCheckCircle,
  faUserCircle,
  faGauge,
  faPencil,
  faDumbbell,
  faEye,
  faIdCard,
  faAddressBook,
  faContactCard,
  faChartPie,
  faCogs,
  faRibbon,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";

import {
  putExerciseUpdateAPI,
  getExerciseDetailAPI,
} from "../../../API/Exercise";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import FormCountryField from "../../Reusable/FormCountryField";
import FormRegionField from "../../Reusable/FormRegionField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormSelectFieldForOffer from "../../Reusable/FormSelectFieldForOffer";
import { topAlertMessageState, topAlertStatusState } from "../../../AppState";
import {
  EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION,
  EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION,
  EXERCISE_TYPE_WITH_EMPTY_OPTIONS,
  EXERCISE_STATUS_OPTIONS_WITH_EMPTY_OPTION,
  EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION,
  TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS,
  BADGE_CRITERIA_WITH_EMPTY_OPTIONS,
  FILE_UPLOAD_OPTIONS,
} from "../../../Constants/FieldOptions";
import {
  EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
  EXERCISE_VIDEO_TYPE_YOUTUBE,
  EXERCISE_VIDEO_TYPE_VIMEO,
  EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
  EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL,
} from "../../../Constants/App";
import { getBadgeDetailAPI, putBadgeUpdateAPI } from "../../../API/badge";
import Layout from "../../Menu/Layout";

function AdminBadgeUpdate() {
  ////
  //// URL Parameters.
  ////

  const { id } = useParams();

  ////
  //// Global state.
  ////

  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);

  ////
  //// Component states.
  ////

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnailType, setThumbnailType] = useState(1);
  const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
  const [thumbnailAttachmentName, setThumbnailAttachmentName] = useState("");
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [criteria, setCriteria] = useState("");

  // Breadcrumb items
  const breadcrumbItems = {
    items: [
      {
        text: "Dashboard",
        link: "/admin/dashboard",
        isActive: false,
        icon: faGauge,
      },
      {
        text: "Badges",
        link: "/admin/badges",
        isActive: false,
        icon: faRibbon,
      },
      {
        text: "Detail",
        link: `/admin/badges/${id}`,
        isActive: false,
        icon: faEye,
      },
      { text: "Edit", link: "#", isActive: true, icon: faPencil },
    ],
  };

  ////
  //// Event handling.
  ////

  const onSubmitClick = (e) => {
    console.log("onSubmitClick: Starting...");
    setFetching(true);
    setErrors({});
    putBadgeUpdateAPI(
      {
        id,
        name,
        description,
        thumbnail_type: thumbnailType,
        thumbnail_attachment_id: thumbnailAttachmentID,
        thumbnail_attachment_name: thumbnailAttachmentName,
        thumbnail_url: thumbnailURL,
        criteria: parseInt(criteria),
      },
      onUpdateSuccess,
      onUpdateError,
      onUpdateDone
    );
    console.log("onSubmitClick: Finished.");
  };

  ////
  //// API.
  ////

  // --- Exercise Update --- //

  function onUpdateSuccess(response) {
    // For debugging purposes only.
    console.log("onUpdateSuccess: Starting...");
    console.log(response);

    // Update a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Badge updated");
    setTopAlertStatus("success");
    setTimeout(() => {
      console.log("onUpdateSuccess: Delayed for 2 seconds.");
      console.log(
        "onUpdateSuccess: topAlertMessage, topAlertStatus:",
        topAlertMessage,
        topAlertStatus
      );
      setTopAlertMessage("");
    }, 2000);

    // Redirect the organization to the organization attachments page.
    setForceURL(`/admin/badges/${response.id}`);
  }

  function onUpdateError(apiErr) {
    console.log("onUpdateError: Starting...");
    setErrors(apiErr);

    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      console.log("onUpdateError: Delayed for 2 seconds.");
      console.log(
        "onUpdateError: topAlertMessage, topAlertStatus:",
        topAlertMessage,
        topAlertStatus
      );
      setTopAlertMessage("");
    }, 2000);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onUpdateDone() {
    console.log("onUpdateDone: Starting...");
    setFetching(false);
  }

  // --- Exercise Detail --- //

  function onDetailSuccess(response) {
    // For debugging purposes only.
    console.log("onDetailSuccess: Starting...");
    console.log(response);

    // DEVELOPERS NOTE: If zero string then that's a MongoDB empty db record so handle the code as follows.
    if (response.thumbnailAttachmentId === "000000000000000000000000") {
      response.thumbnailAttachmentId = "";
    } else {
      setThumbnailAttachmentID(response.thumbnailAttachmentId);
    }

    setThumbnailType(response.thumbnailType);
    setThumbnailURL(response.thumbnailUrl);
    setThumbnailAttachmentName(response.thumbnailAttachmentName);
    setDescription(response.description);
    setName(response.name);
    setCriteria(response.criteria);
  }

  function onDetailError(apiErr) {
    console.log("onDetailError: Starting...");
    setErrors(apiErr);

    // Detail a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      console.log("onDetailError: Delayed for 2 seconds.");
      console.log(
        "onDetailError: topAlertMessage, topAlertStatus:",
        topAlertMessage,
        topAlertStatus
      );
      setTopAlertMessage("");
    }, 2000);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onDetailDone() {
    console.log("onDetailDone: Starting...");
    setFetching(false);
  }

  ////
  //// Misc.
  ////

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.
      setFetching(true);
      getBadgeDetailAPI(id, onDetailSuccess, onDetailError, onDetailDone);
    }

    return () => {
      mounted = false;
    };
  }, [id]);

  ////
  //// Component rendering.
  ////

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <Layout breadcrumbItems={breadcrumbItems}>
      {/* Page */}
      <div class="box">
        <div class="columns">
          <div class="column">
            <p class="title is-4">
              <FontAwesomeIcon className="fas" icon={faRibbon} />
              &nbsp;Badge
            </p>
          </div>
          <div class="column has-text-right"></div>
        </div>
        <FormErrorBox errors={errors} />

        <p class="pb-4 mb-5 has-text-grey">
          Please fill out all the required fields before submitting this form.
        </p>

        {isFetching ? (
          <PageLoadingContent displayMessage={"Please wait..."} />
        ) : (
          <>
            <FormInputField
              name="name"
              placeholder="Enter the badge name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isRequired={true}
              maxWidth="380px"
              label="Name"
              errorText={errors && errors.name}
            />

            <FormTextareaField
              rows={2}
              name="description"
              placeholder="Describe the badge"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              isRequired={true}
              maxWidth="380px"
              label="Description"
              errorText={errors && errors.description}
            />

            <FormSelectField
              name="criteria"
              label="Criteria"
              helpText={"Choose the criteria for earning this badge"}
              errorText={errors && errors.criteria}
              options={BADGE_CRITERIA_WITH_EMPTY_OPTIONS}
              maxWidth="380px"
              selectedValue={criteria}
              onChange={(e) => setCriteria(e.target.value)}
            />

            <FormSelectField
              label="Icon"
              name="thumbnailType"
              options={FILE_UPLOAD_OPTIONS}
              selectedValue={thumbnailType}
              onChange={(e) => setThumbnailType(parseInt(e.target.value))}
              errorText={errors && errors.thumbnailType}
              maxWidth="180px"
            />

            {thumbnailType === 1 ? (
              <FormAttachmentField
                label="Upload Badge Image"
                name="image"
                attachmentID={thumbnailAttachmentID}
                setAttachmentID={setThumbnailAttachmentID}
                attachmentFilename={thumbnailAttachmentName}
                setAttachmentFilename={setThumbnailAttachmentName}
                errorText={errors && errors.thumbnailUpload}
              />
            ) : (
              <FormInputField
                label="Badge Image URL"
                name="thumbnailUrl"
                value={thumbnailURL}
                onChange={(e) => setThumbnailURL(e.target.value)}
                errorText={errors && errors.thumbnailUrl}
              />
            )}
            <div className="columns pt-5">
              <div className="column is-half">
                <Link
                  className="button is-fullwidth-mobile"
                  to={`/admin/badges/${id}`}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                  &nbsp;Back to Badge Details
                </Link>
              </div>
              <div className="column is-half has-text-right">
                <button
                  onClick={onSubmitClick}
                  className="button is-success is-fullwidth-mobile"
                  type="button"
                >
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;Save Changes
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default AdminBadgeUpdate;
