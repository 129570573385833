import { useQuery } from "@tanstack/react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import { accountState, currentUserState } from "../AppState";
import { getAccountDetailAPIUsingPromise } from "../API/Account";

export const useAccountQuery = () => {
  const [account, setAccount] = useRecoilState(accountState);
  const currentUser = useRecoilValue(currentUserState); // Get the current user

  // Call useQuery regardless, but control its behavior using the 'enabled' option
  return useQuery({
    queryKey: ['accountDetail'],
    queryFn: getAccountDetailAPIUsingPromise,
    enabled: !!currentUser, // Ensure query only runs if `currentUser` exists
    onSuccess: (data) => {
      setAccount(data); // Update Recoil state when data is fetched
    },
    cacheTime: 5 * 60 * 1000, // Cache for 5 minutes
    staleTime: 2 * 60 * 1000, // Data is considered fresh for 2 minutes
    refetchOnWindowFocus: false, // Disable refetch when window regains focus
  });
};
