import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faLevelUp,
  faGauge,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormTextareaField from "../../Reusable/FormTextareaField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormSelectField from "../../Reusable/FormSelectField";
import FormInputField from "../../Reusable/FormInputField";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import { topAlertMessageState, topAlertStatusState } from "../../../AppState";
import Layout from "../../Menu/Layout";
import { postLevelCreateAPI } from "../../../API/level";
import { BADGE_CRITERIA_WITH_EMPTY_OPTIONS } from "../../../Constants/FieldOptions";

function AdminLevelAdd() {
  const [, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [, setTopAlertStatus] = useRecoilState(topAlertStatusState);

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnailType, setThumbnailType] = useState(1);
  const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
  const [thumbnailAttachmentName, setThumbnailAttachmentName] = useState("");
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [criteria, setCriteria] = useState("");
  const [challengesRequired, setChallengesRequired] = useState(0);

  // Breadcrumb items
  const breadcrumbItems = {
    items: [
      {
        text: "Dashboard",
        link: "/admin/dashboard",
        isActive: false,
        icon: faGauge,
      },
      {
        text: "Levels",
        link: "/admin/levels",
        isActive: false,
        icon: faLevelUp,
      },
      { text: "Add Level", link: "#", isActive: true, icon: faPlus },
    ],
    mobileBackLinkItems: {
			link: "/admin/levels",
			text: "Back to Levels",
			icon: faArrowLeft,
		},
  };

  // Callbacks
  const onSubmitSuccess = (newLevel) => {
    console.log("onSubmitSuccess: Level saved to localStorage", newLevel);
    setTopAlertStatus("success");
    setTopAlertMessage("Level successfully added!");

    // Clear form fields after submission
    setName("");
    setDescription("");
    setThumbnailType(1);
    setThumbnailAttachmentID("");
    setThumbnailAttachmentName("");
    setThumbnailURL("");
    setCriteria("");
    setChallengesRequired(0);

    // Redirect to levels list
    setForceURL("/admin/levels");

    // Clear the alert message after 2 seconds
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
  };

  const onSubmitError = (error) => {
    console.error("onSubmitError: Failed to save level", error);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed to add level.");
    setErrors(error);

    // Clear the alert message after 2 seconds
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
  };

  const onSubmitDone = () => {
    setFetching(false);
  };

  const onSubmitClick = () => {
    setFetching(true);
    setErrors({});

    let newLevel = {
      name,
      description,
      thumbnail_type: thumbnailType,
      thumbnail_attachment_id: thumbnailAttachmentID,
      thumbnail_attachment_name: thumbnailAttachmentName,
      thumbnail_url: thumbnailURL,
      num_challenges: parseInt(challengesRequired),
    };
    postLevelCreateAPI(newLevel, onSubmitSuccess, onSubmitError, onSubmitDone);
  };

  // Redirect if needed
  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <Layout breadcrumbItems={breadcrumbItems}>
      <div className="box">
        <p className="title is-4">
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;Add Level
        </p>
        <FormErrorBox errors={errors} />
        <p className="pb-4 mb-5 has-text-grey">
          Please fill out all the required fields before submitting this form.
        </p>

        {isFetching ? (
          <PageLoadingContent displayMessage={"Please wait..."} />
        ) : (
          <>
            <FormInputField
              name="name"
              placeholder="Enter the level name (e.g., Level 1)"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isRequired={true}
              maxWidth="380px"
              label="Level Name"
              errorText={errors && errors.name}
            />

            <FormTextareaField
              rows={2}
              name="description"
              placeholder="Describe the level"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              isRequired={true}
              maxWidth="380px"
              label="Description"
              errorText={errors && errors.description}
            />

            <FormInputField
              name="challengesRequired"
              placeholder="Number of challenges required"
              type="number"
              value={challengesRequired}
              onChange={(e) => setChallengesRequired(parseInt(e.target.value))}
              isRequired={true}
              maxWidth="380px"
              label="Challenges Required"
              errorText={errors && errors.challengesRequired}
            />

            <FormSelectField
              name="criteria"
              label="Criteria"
              helpText={"Choose the criteria to complete this level"}
              errorText={errors && errors.criteria}
              options={BADGE_CRITERIA_WITH_EMPTY_OPTIONS}
              maxWidth="380px"
              selectedValue={criteria}
              onChange={(e) => setCriteria(e.target.value)}
            />

            <FormSelectField
              label="Icon"
              name="thumbnailType"
              value={thumbnailType}
              options={[
                { label: "File Upload", value: 1 },
                { label: "External URL", value: 2 },
              ]}
              onChange={(e) => setThumbnailType(parseInt(e.target.value))}
              errorText={errors && errors.thumbnailType}
              maxWidth="180px"
            />

            {thumbnailType === 1 ? (
              <FormAttachmentField
                label="Upload Level Image"
                name="image"
                attachmentID={thumbnailAttachmentID}
                setAttachmentID={setThumbnailAttachmentID}
                attachmentFilename={thumbnailAttachmentName}
                setAttachmentFilename={setThumbnailAttachmentName}
                errorText={errors && errors.thumbnailUpload}
                helpText="Please upload a square image (32x32)px for optimal display."
              />
            ) : (
              <FormInputField
                label="Level Image URL"
                name="thumbnailUrl"
                value={thumbnailURL}
                onChange={(e) => setThumbnailURL(e.target.value)}
                errorText={errors && errors.thumbnailUrl}
                helpText="Please ensure the image URL points to a square image of size (32x32)px."
              />
            )}

            <div className="columns pt-5">
              <div className="column is-half">
                <Link className="button is-fullwidth-mobile" to="/admin/levels">
                  <FontAwesomeIcon icon={faArrowLeft} />
                  &nbsp;Back to Levels
                </Link>
              </div>
              <div className="column is-half has-text-right">
                <button
                  onClick={onSubmitClick}
                  className="button is-success is-fullwidth-mobile"
                  type="button"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;Submit
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default AdminLevelAdd;
