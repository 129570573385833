import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faCalendarMinus,
	faCalendarPlus,
	faCheck,
	faEye,
} from "@fortawesome/free-solid-svg-icons";
import {
	PAGE_SIZE_OPTIONS,
	WORKOUT_STATUS_MAP,
} from "../../../Constants/FieldOptions";
import {currentUserState} from "../../../AppState";
import {useRecoilState} from "recoil";

/*
Display for both tablet and mobile.
*/
function MemberListMobile(props) {
	const {
		listData,
		setPageSize,
		pageSize,
		previousCursors,
		onPreviousClicked,
		onNextClicked,
		onChangeStatus,
	} = props;

	const [currentUser] = useRecoilState(currentUserState);

	return (
		<>
			{listData &&
				listData.results &&
				listData.results.map(function (datum, i) {
					const currentUserData = datum.users?.find(
						(u) => u.id === currentUser.id
					);

					return (
						<div className="pb-2" key={`mobile_tablet_${datum.id}`}>
							<strong>Name:</strong>&nbsp;{datum.name}
							<br />
							<br />
							<strong>Users count:</strong>&nbsp;{datum.users?.length}
							<br />
							<br />
							<strong>Category:</strong>&nbsp;
							{datum.category === 2 ? "Self Update Based" : "Biometric based"}
							<br />
							<br />
							<strong>Status:</strong>&nbsp;{WORKOUT_STATUS_MAP[datum.status]}
							<br />
							<br />
							<strong>Created At:</strong>&nbsp;{datum.createdAt || " - "}
							<br />
							<br />
							{/* Tablet only */}
							<div className="is-hidden-mobile" key={`tablet_${datum.id}`}>
								<div className="buttons is-right">
									<Link
										to={`/fitness-challenge/${datum.id}`}
										className="button is-small is-dark"
										type="button">
										<FontAwesomeIcon className="mdi" icon={faEye} />
										&nbsp;View
									</Link>
									{/* Handle buttons based on user completion status or if not present */}
									{currentUserData ? (
										currentUserData.completed ? (
											<button className="button is-small is-success" disabled>
												<FontAwesomeIcon className="mdi" icon={faCheck} />
												&nbsp;Completed
											</button>
										) : (
											<button
												className="button is-small is-danger"
												onClick={() => onChangeStatus(datum.id)} // Leaves the challenge
											>
												<FontAwesomeIcon
													className="mdi"
													icon={faCalendarMinus}
												/>
												&nbsp;Leave
											</button>
										)
									) : (
										<button
											className="button is-small is-success"
											onClick={() => onChangeStatus(datum.id)} // Joins the challenge
										>
											<FontAwesomeIcon className="mdi" icon={faCalendarPlus} />
											&nbsp;Join
										</button>
									)}
								</div>
							</div>
							{/* Mobile only */}
							<div className="is-hidden-tablet" key={`mobile_${datum.id}`}>
								<div className="columns is-mobile">
									<div className="column">
										<Link
											to={`/fitness-challenge/${datum.id}`}
											className="mt-2 button is-small is-dark is-fullwidth"
											type="button">
											<FontAwesomeIcon className="mdi" icon={faEye} />
											&nbsp;View
										</Link>
										{currentUserData && currentUserData.completed ? (
											<button
												className="mt-2 button is-small is-success is-fullwidth"
												disabled>
												<FontAwesomeIcon className="mdi" icon={faCheck} />
												&nbsp;Completed
											</button>
										) : (
											<>
												{currentUserData ? (
													<button
														className="mt-2 button is-small is-danger is-fullwidth"
														onClick={() => onChangeStatus(datum.id)} // Leaves the challenge
													>
														<FontAwesomeIcon
															className="mdi"
															icon={faCalendarMinus}
														/>
														&nbsp;Leave
													</button>
												) : (
													<button
														className="mt-2 button is-small is-success is-fullwidth"
														onClick={() => onChangeStatus(datum.id)} // Joins the challenge
													>
														<FontAwesomeIcon
															className="mdi"
															icon={faCalendarPlus}
														/>
														&nbsp;Join
													</button>
												)}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					);
				})}

			<div className="columns pt-4 is-mobile">
				<div className="column is-half">
					<span className="select">
						<select
							className={`input has-text-grey-light`}
							name="pageSize"
							onChange={(e) => setPageSize(parseInt(e.target.value))}>
							{PAGE_SIZE_OPTIONS.map(function (option, i) {
								return (
									<option
										selected={pageSize === option.value}
										value={option.value}>
										{option.label}
									</option>
								);
							})}
						</select>
					</span>
				</div>
				<div className="column is-half has-text-right">
					{previousCursors.length > 0 && (
						<button className="button" onClick={onPreviousClicked}>
							Previous
						</button>
					)}
					{listData.hasNextPage && (
						<>
							<button className="button" onClick={onNextClicked}>
								Next
							</button>
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default MemberListMobile;
