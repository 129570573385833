import React from "react";

const ToggleSwitch = ({
  labelLeft,
  labelRight,
  checked,
  onToggle
}) => {
  return (
    
    <div className="columns is-mobile is-vcentered is-gapless">
  {/* Toggle switch column */}
  <div className="column is-narrow">
    <label
      id="switch"
      className={`toggle ${checked ? "checked" : ""}`}
      onClick={onToggle}  // Handle toggle when clicked
    >
      {/* Optional visual handle for the switch */}
      <span className="switch-handle"></span>
    </label>
  </div>

  {/* Label column */}
  <div className="column is-narrow">
    <p className="ml-1">{checked ? labelLeft : labelRight}</p>
  </div>
</div>

  );
};

export default ToggleSwitch;
