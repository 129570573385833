import {useState, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faBolt,
	faEdit,
	faEye,
	faGauge,
	faPlus,
	faRankingStar,
	faTable,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Scroll from "react-scroll";
import {Link, useParams} from "react-router-dom";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormDateTimeField from "../../Reusable/FormDateTimeField";
import FormInputField from "../../Reusable/FormInputField";
import {
	challengePointFilterSortState,
	currentUserState,
	topAlertMessageState,
	topAlertStatusState,
} from "../../../AppState";
import {useRecoilState} from "recoil";
import {
	getfitnessChallengePointListApi,
	postfitnessChallengePointCreateAPI,
	putfitnessChallengePointUpdateAPI,
} from "../../../API/FitnessChallengePoints";
import {getfitnessChallengeDetailAPI} from "../../../API/FitnessChallenge";
import TabularDesktop from "./pointsTabularDesktop";
import TabularMobile from "./pointsTabularmobile";
import Layout from "../../Menu/Layout";

const UserPointsHistory = () => {
	const {id} = useParams();

	const [currentUser] = useRecoilState(currentUserState);
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);

	const [sort, setSort] = useRecoilState(challengePointFilterSortState);

	const [pointsHistory, setPointsHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState({});
	const [showAddModal, setshowAddModal] = useState(false);
	const [day, setDay] = useState(null);
	const [newPoint, setnewPoint] = useState("");
	const [datum, setDatum] = useState({});
	const [challenge, setChallenge] = useState({});
	const [minDate, setminDate] = useState(new Date());
	const [maxDate, setmaxDate] = useState(new Date());
	const [activity, setactivity] = useState("");
	const [notes, setnotes] = useState("");
	const [pageSize, setPageSize] = useState(1); // Pagination
	const [previousCursors, setPreviousCursors] = useState([]); // Pagination
	const [nextCursor, setNextCursor] = useState(""); // Pagination
	const [currentCursor, setCurrentCursor] = useState(""); // Paginationc
	const [selectedPoint, setselectedPOint] = useState({});
	const [showUpdateModal, setshowUpdateModal] = useState(false);
	const [showDeleteModal, setshowDeleteModal] = useState(false);
	const [updateData, setupdateData] = useState({});

	////
	//// BREADCRUMB
	////
	const breadcrumbItems = {
		items: [
			{text: "Dashboard", link: "/dashboard", isActive: false, icon: faGauge},
			{
				text: "Fitness Challenge",
				link: "/fitness-challenge",
				icon: faBolt,
				isActive: false,
			},
			{text: "Points", link: "#", icon: faRankingStar, isActive: true},
		],
		mobileBackLinkItems: {
			link: `/fitness-challenge/${id}`,
			text: "Back to Detail",
			icon: faArrowLeft,
		},
	};

	const addWeeksToDate = (date, weeks) => {
		const result = new Date(date);
		result.setDate(result.getDate() + weeks * 7);
		return result;
	};

	const isWithinDateRange = (date, minDate, maxDate) => {
		return date >= minDate && date <= maxDate;
	};
	const filterDate = (date) => {
		return isWithinDateRange(date, minDate, maxDate);
	};

	function onDetailSuccess(response) {
		setChallenge(response);
		setminDate(new Date(response.startTime));
		setmaxDate(addWeeksToDate(response.startTime, response.durationInWeeks));
	}

	function onDetailError(apiErr) {
		setError(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onDetailDone() {
		setIsLoading(false);
	}

	const onSubmitClick = (e) => {
		// Logic to submit data
		e.preventDefault();
		setIsLoading(true);
		let payload = {
			user_id: currentUser.id,
			challenge_id: id,
			day: day,
			value: parseInt(newPoint),
			activity: activity,
			notes: notes,
			organization_id: currentUser.organizationId,
		};

		postfitnessChallengePointCreateAPI(
			payload,
			onAddSuccess,
			onAddError,
			onAddDone
		);
	};

	function onAddSuccess(response) {
		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("challenge point added");
		setTopAlertStatus("success");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
	}

	function onAddError(apiErr) {
		setError(apiErr);
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onAddDone() {
		setIsLoading(false);
		setshowAddModal(false);
		fetchList(currentCursor, pageSize, sort);
		setDay(null);
		setnewPoint("");
		setactivity("");
		setnotes("");
	}

	const onUpdate = (isdelete = false) => {
		setIsLoading(true);
		setshowUpdateModal(false);
		setshowDeleteModal(false);
		putfitnessChallengePointUpdateAPI(
			id,
			{
				id: id,
				point_id: selectedPoint.id,
				day: updateData.date ? updateData.date : selectedPoint.date,
				value: updateData.value
					? parseInt(updateData.value)
					: parseInt(selectedPoint.value),
				notes: updateData.notes ? updateData.notes : selectedPoint.notes,
				activity: updateData.activity
					? updateData.activity
					: selectedPoint.activity,
				delete: isdelete === true,
				user_id: currentUser.id,
				challenge_id: id,
			},
			onupdateOk,
			onAddError,
			onupdateDone
		);
	};

	const onupdateOk = () => {
		setTopAlertMessage("challenge point added");
		setTopAlertStatus("success");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
	};

	const onupdateDone = () => {
		setselectedPOint({});
		setupdateData({});
		setIsLoading(false);
		fetchList(currentCursor, pageSize, sort);
	};

	const fetchList = (cur, limit, sbv, keywords, st, vt) => {
		setIsLoading(true);
		setError({});

		let params = new Map();
		params.set("page_size", limit); // Pagination

		if (sbv !== undefined && sbv !== null && sbv !== "") {
			const sortArray = sbv.split(",");
			params.set("sort_field", sortArray[0]); // Sort (1 of 2)
			params.set("sort_order", sortArray[1]); // Sort (2 of 2)
		}

		if (cur !== "") {
			// Pagination
			params.set("cursor", cur);
		}

		params.set("user_id", currentUser.id);
		params.set("challenge_id", id);

		getfitnessChallengePointListApi(
			params,
			onListSuccess,
			onlistError,
			onListDOne
		);
	};

	function onListSuccess(response) {
		if (response.results !== null && response.results.length > 0) {
			setDatum(response);
			if (response.results[0] && response.results[0].points) {
				setPointsHistory(response.results[0].points);
			}
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor); // For pagination purposes.
			}
		} else {
			setDatum([]);
			setNextCursor("");
		}
	}

	function onlistError(apiErr) {
		setError(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onListDOne() {
		setIsLoading(false);
	}
	const onNextClicked = (e) => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	useEffect(() => {
		// setIsLoading(true);
		fetchList(currentCursor, pageSize, sort);

		if (!datum.id) {
			getfitnessChallengeDetailAPI(
				id,
				onDetailSuccess,
				onDetailError,
				onDetailDone
			);
		}
	}, []);

	return (
		<Layout breadcrumbItems={breadcrumbItems}>
			{/* Page */}
			<div class="box">
				{/* Title + Options */}
				{pointsHistory && (
					<div class="columns">
						<div class="column">
							<p class="title is-4">
								<FontAwesomeIcon className="fas" icon={faBolt} />
								&nbsp;Challenges
							</p>
						</div>
					</div>
				)}
				{/* Tab Navigation */}
				<div class="tabs is-medium is-size-7-mobile">
					<ul>
						<li class="">
							<Link to={`/fitness-challenge/${id}`}>
								<strong>Detail</strong>
							</Link>
						</li>
						<li class="is-active">
							<Link to={`/fitness-challenge/${id}/points`} aria-current="page">
								<strong>Points</strong>
							</Link>
						</li>
						<li class="">
							<Link to={`/fitness-challenge/${id}/leaderboard`}>
								<strong>LeaderBoard</strong>
							</Link>
						</li>
					</ul>
				</div>
				{/* Add modal */}
				<div class={`modal ${showAddModal ? "is-active" : ""}`}>
					<div class="modal-background"></div>
					<div class="modal-card">
						<header class="modal-card-head">
							<p class="modal-card-title">Add points</p>
							<button
								class="delete"
								aria-label="close"
								onClick={(e, ses) => setshowAddModal(false)}></button>
						</header>
						{challenge.users &&
						challenge.users.filter((u) => u.id === currentUser.id).map((f) => f)
							.length > 0 ? (
							<section class="modal-card-body">
								<FormDateTimeField
									label={"Date :"}
									helpText={"Add the date which you completed the points"}
									onChange={(date) => setDay(date)}
									value={day}
									placeholder={"day"}
									maxWidth={"360px"}
									name={"day"}
									filterDate={filterDate}
									errorText={error && error.day}
								/>
								<FormInputField
									label={"Points"}
									helpText={"Enter the points you have achieved"}
									value={newPoint}
									onChange={(e) => setnewPoint(e.target.value)}
									placeholder={"Points achieved"}
									type="number"
								/>
								<FormInputField
									label={"Activity"}
									helpText={"Activity - (Walking, Running etc"}
									value={activity}
									onChange={(e) => setactivity(e.target.value)}
									placeholder={"activity"}
									type="text"
								/>
								<FormInputField
									label={"Notes"}
									helpText={"Optional Notes"}
									value={notes}
									onChange={(e) => setnotes(e.target.value)}
									placeholder={"notes"}
									type="text"
								/>
							</section>
						) : (
							<p class="modal-card-body"> Join this challenge to add points</p>
						)}
						<footer class="modal-card-foot">
							<button
								disabled={!(day && newPoint)}
								class="button is-success"
								onClick={(e) => onSubmitClick(e)}>
								Add Point&nbsp; <FontAwesomeIcon icon={faPlus} />
							</button>
							<button
								class="button"
								onClick={(e, ses) => setshowAddModal(false)}>
								Cancel
							</button>
						</footer>
					</div>
				</div>
				{/* Update modal */}
				<div class={`modal ${showUpdateModal ? "is-active" : ""}`}>
					<div class="modal-background"></div>
					<div class="modal-card">
						<header class="modal-card-head">
							<p class="modal-card-title">Edit points</p>
							<button
								class="delete"
								aria-label="close"
								onClick={(e, ses) => setshowUpdateModal(false)}></button>
						</header>
						<section class="modal-card-body">
							<FormDateTimeField
								label={"Day :"}
								helpText={"Add the date which you completed the points"}
								onChange={(date) => setupdateData({...updateData, date: date})}
								value={
									updateData.date != null
										? updateData.date
										: selectedPoint?.date
								}
								placeholder={"day"}
								maxWidth={"360px"}
								name={"day"}
								filterDate={filterDate}
								errorText={error && error.day}
							/>
							<FormInputField
								label={"Points"}
								helpText={"Enter the points you have achieved"}
								value={
									updateData.value != null
										? updateData.value
										: selectedPoint?.value
								}
								onChange={(e) =>
									setupdateData((updateData) => ({
										...updateData,
										value: e.target.value,
									}))
								}
								placeholder={"Points achieved"}
								type="number"
							/>
							<FormInputField
								label={"Activity"}
								helpText={"Activity - (Walking, Running etc"}
								value={
									updateData.activity != null
										? updateData.activity
										: selectedPoint?.activity
								}
								onChange={(e) =>
									setupdateData({
										...updateData,
										activity: e.target.value,
									})
								}
								placeholder={"activity"}
								type="text"
							/>
							<FormInputField
								label={"Notes"}
								helpText={"Optional Notes"}
								value={
									updateData.notes != null
										? updateData.notes
										: selectedPoint?.notes
								}
								onChange={(e) =>
									setupdateData({...updateData, notes: e.target.value})
								}
								placeholder={"notes"}
								type="text"
							/>
						</section>
						<footer class="modal-card-foot">
							<button
								class="button is-responsive is-success"
								onClick={() => onUpdate()}>
								Edit Point&nbsp; <FontAwesomeIcon icon={faEdit} />
							</button>
							<button
								class="button"
								onClick={(e, ses) => setshowUpdateModal(false)}>
								Cancel
							</button>
						</footer>
					</div>
				</div>
				{/* /delete modal */}
				<div class={`modal ${showDeleteModal ? "is-active" : ""}`}>
					<div class="modal-background"></div>
					<div class="modal-card">
						<header class="modal-card-head">
							<p class="modal-card-title">Remove points</p>
							<button
								class="delete"
								aria-label="close"
								onClick={(e, ses) => setshowDeleteModal(false)}></button>
						</header>
						<section class="modal-card-body">
							Are you sure to delete the point
						</section>
						<footer class="modal-card-foot">
							<button
								class="button is-responsive is-danger"
								onClick={() => onUpdate(true)}>
								Delete Point&nbsp; <FontAwesomeIcon icon={faTrash} />
							</button>
							<button
								class="button"
								onClick={(e, ses) => setshowDeleteModal(false)}>
								Cancel
							</button>
						</footer>
					</div>
				</div>

				<div className="is-flex is-justify-content-space-between">
					<h2 className="is-4 title">Points History</h2>

					<button
						className="button is-small is-success"
						onClick={() => setshowAddModal(true)}>
						Add Entry
					</button>
				</div>
				{isLoading ? (
					<PageLoadingContent displayMessage={"Please wait..."} />
				) : (
					<>
						{pointsHistory.length === 0 ? (
							<section className="hero is-medium has-background-white-ter">
								<div className="hero-body">
									<p className="title">
										<FontAwesomeIcon className="fas" icon={faTable} />
										&nbsp;No Fitness Challenge Points
									</p>
									<p className="subtitle">No Challenges points available.</p>
								</div>
							</section>
						) : (
							<>
								<div className="is-hidden-touch">
									<TabularDesktop
										setselectedPOint={setselectedPOint}
										setshowUpdateModal={setshowUpdateModal}
										setshowDeleteModal={setshowDeleteModal}
										pointsHistory={pointsHistory}
									/>
								</div>
								<div className="is-hidden-desktop">
									<TabularMobile
										setselectedPOint={setselectedPOint}
										setshowUpdateModal={setshowUpdateModal}
										setshowDeleteModal={setshowDeleteModal}
										pointsHistory={pointsHistory}
									/>
								</div>
							</>
						)}
					</>
				)}
			</div>
		</Layout>
	);
};

export default UserPointsHistory;
