import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faLightbulb,
  faPlus,
  faGauge,
  faArrowRight,
  faTable,
  faRefresh,
  faFilter,
  faSearch,
  faFilterCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import { getTipsListAPI, deleteTipAPI } from "../../../API/tips"; // Change API import to use tips
import {
  topAlertMessageState,
  topAlertStatusState,
  currentUserState,
  tipsFilterShowState,
  tipsFilterTemporarySearchTextState,
  tipsFilterActualSearchTextState,
  tipsFilterSortState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import AdminTipsListDesktop from "./ListDesktop"; // Ensure this component is created for tips
import AdminTipsListMobile from "./ListMobile"; // Ensure this component is created for tips
import Modal from "../../Reusable/modal";
import FormSelectField from "../../Reusable/FormSelectField";
import { TIPS_STATUS_OPTIONS } from "../../../Constants/FieldOptions";

function AdminTipsList() {
  const [, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [, setTopAlertStatus] = useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);
  const [showFilter, setShowFilter] = useRecoilState(tipsFilterShowState);
  const [sort] = useRecoilState(tipsFilterSortState);
  const [temporarySearchText, setTemporarySearchText] = useRecoilState(
    tipsFilterTemporarySearchTextState
  );
  const [status, setStatus] = useState(1); //Status
  const [actualSearchText, setActualSearchText] = useRecoilState(
    tipsFilterActualSearchTextState
  );

  const [errors, setErrors] = useState({});
  const [listData, setListData] = useState([]);
  const [selectedTipForDeletion, setSelectedTipForDeletion] = useState("");
  const [isFetching, setFetching] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [previousCursors, setPreviousCursors] = useState([]);
  const [nextCursor, setNextCursor] = useState("");
  const [currentCursor, setCurrentCursor] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onTipsListSuccess = (response) => {
    if (response.results) {
      setListData(response);
      if (response.hasNextPage) {
        setNextCursor(response.nextCursor);
      }
    } else {
      setListData([]);
      setNextCursor("");
    }
  };

  const onTipsListError = (apiErr) => {
    setErrors(apiErr);
    Scroll.animateScroll.scrollToTop();
  };

  const onTipsListDone = () => {
    setFetching(false);
  };

  const onTipsDeleteSuccess = () => {
    setTopAlertStatus("success");
    setTopAlertMessage("Tip deleted");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    fetchList(currentCursor, pageSize, actualSearchText, sort);
  };

  const onTipsDeleteError = (apiErr) => {
    setErrors(apiErr);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed deleting");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    Scroll.animateScroll.scrollToTop();
  };

  const onTipsDeleteDone = () => {
    setFetching(false);
  };

  const fetchList = (cur, limit, keywords, sbv) => {
    setFetching(true);
    setErrors({});
    let params = new Map();
    params.set("page_size", limit);
    if (cur !== "") {
      params.set("cursor", cur);
    }
    if (keywords) {
      params.set("search", keywords);
    }
    if (sbv) {
      const sortArray = sbv.split(",");
      params.set("sort_field", sortArray[0]);
      params.set("sort_order", sortArray[1]);
    }
    params.set("status", status);
    getTipsListAPI(params, onTipsListSuccess, onTipsListError, onTipsListDone);
  };

  const onNextClicked = () => {
    let arr = [...previousCursors];
    arr.push(currentCursor);
    setPreviousCursors(arr);
    setCurrentCursor(nextCursor);
  };

  const onPreviousClicked = () => {
    let arr = [...previousCursors];
    const previousCursor = arr.pop();
    setPreviousCursors(arr);
    setCurrentCursor(previousCursor);
  };

  const onSearchButtonClick = () => {
    setActualSearchText(temporarySearchText);
  };

  const onSelectTipForDeletion = (e, datum) => {
    setSelectedTipForDeletion(datum);
    setIsModalOpen(true);
  };

  const onDeselectTipForDeletion = () => {
    setSelectedTipForDeletion("");
    setIsModalOpen(false);
  };

  const onDeleteConfirmButtonClick = () => {
    deleteTipAPI(
      selectedTipForDeletion.id,
      onTipsDeleteSuccess,
      onTipsDeleteError,
      onTipsDeleteDone
    );
    setIsModalOpen(false);
    setSelectedTipForDeletion("");
  };

  const onClearFilterClick = () => {
    setShowFilter(false);
    setActualSearchText("");
    setTemporarySearchText("");
  };

  useEffect(() => {
    fetchList(currentCursor, pageSize, actualSearchText, sort, status);
  }, [currentCursor, pageSize, actualSearchText, sort, status]);

  return (
    <>
      <div className="container">
        <section className="section">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link to="/admin/dashboard">
                  <FontAwesomeIcon icon={faGauge} />
                  &nbsp;Dashboard
                </Link>
              </li>
              <li className="is-active">
                <Link>
                  <FontAwesomeIcon icon={faLightbulb} />
                  &nbsp;Tips
                </Link>
              </li>
            </ul>
          </nav>

          <nav className="box">
            <Modal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              header="Confirm Deletion"
            >
              <p className="pb-4">
                You are about to <b>delete</b> this tip. Once deleted, it will
                be permanently removed and cannot be restored. Are you sure you
                want to proceed with this action?
              </p>
              <footer className="modal-card-foot">
                <button
                  className="button is-danger"
                  onClick={onDeleteConfirmButtonClick}
                >
                  Confirm
                </button>
                <button className="button" onClick={onDeselectTipForDeletion}>
                  Cancel
                </button>
              </footer>
            </Modal>

            {/* TODO: Add modal */}
            <div className="columns">
              <div className="column">
                <h1 className="title is-4">
                  <FontAwesomeIcon className="fas" icon={faLightbulb} />
                  &nbsp;Tips
                </h1>
              </div>
              <div className="column has-text-right">
                <button
                  onClick={() =>
                    fetchList(currentCursor, pageSize, actualSearchText, sort)
                  }
                  class="is-fullwidth-mobile button is-link is-small"
                  type="button"
                >
                  <FontAwesomeIcon className="mdi" icon={faRefresh} />
                  &nbsp;
                  <span class="is-hidden-desktop is-hidden-tablet">
                    Refresh
                  </span>
                </button>
                &nbsp;
                <button
                  onClick={(e) => setShowFilter(!showFilter)}
                  class="is-fullwidth-mobile button is-small is-primary"
                  type="button"
                >
                  <FontAwesomeIcon className="mdi" icon={faFilter} />
                  &nbsp;Filter
                </button>
                &nbsp;
                <Link
                  to={`/admin/tips/add`}
                  className="is-fullwidth-mobile button is-small is-success"
                  type="button"
                >
                  <FontAwesomeIcon className="mdi" icon={faPlus} />
                  &nbsp;New
                </Link>
              </div>
            </div>
            {showFilter && (
              <div
                class="has-background-white-bis"
                style={{ borderRadius: "15px", padding: "20px" }}
              >
                {/* Filter Title + Clear Button */}
                <div class="columns is-mobile">
                  <div class="column is-half">
                    <strong>
                      <u>
                        <FontAwesomeIcon className="mdi" icon={faFilter} />
                        &nbsp;Filter
                      </u>
                    </strong>
                  </div>
                  <div class="column is-half has-text-right">
                    <Link onClick={onClearFilterClick}>
                      <FontAwesomeIcon
                        className="mdi"
                        icon={faFilterCircleXmark}
                      />
                      &nbsp;Clear Filter
                    </Link>
                  </div>
                </div>

                {/* Filter Options */}
                <div class="columns">
                  <div class="column">
                    <FormInputFieldWithButton
                      label={"Search"}
                      name="temporarySearchText"
                      type="text"
                      placeholder="Search by name"
                      value={temporarySearchText}
                      helpText=""
                      onChange={(e) => setTemporarySearchText(e.target.value)}
                      isRequired={true}
                      maxWidth="100%"
                      buttonLabel={
                        <>
                          <FontAwesomeIcon className="fas" icon={faSearch} />
                        </>
                      }
                      onButtonClick={onSearchButtonClick}
                    />
                  </div>
                  <div class="column">
                    <FormSelectField
                      name="status"
                      label="Status"
                      errorText={errors && errors.status}
                      options={TIPS_STATUS_OPTIONS}
                      maxWidth="380px"
                      selectedValue={status}
                      onChange={(e) => setStatus(parseInt(e.target.value))}
                    />
                  </div>
                </div>
              </div>
            )}

            {isFetching ? (
              <PageLoadingContent displayMessage={"Please wait..."} />
            ) : (
              <>
                <FormErrorBox errors={errors} />
                {listData &&
                listData.results &&
                (listData.results.length > 0 || previousCursors.length > 0) ? (
                  <div className="container">
                    <div class="is-hidden-touch">
                      <AdminTipsListDesktop
                        listData={listData}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        previousCursors={previousCursors}
                        onPreviousClicked={onPreviousClicked}
                        onNextClicked={onNextClicked}
                        onSelectTipForDeletion={onSelectTipForDeletion}
                      />
                    </div>
                    <div class="is-fullwidth is-hidden-desktop">
                      <AdminTipsListMobile
                        listData={listData}
                        setPageSize={setPageSize}
                        pageSize={pageSize}
                        previousCursors={previousCursors}
                        onPreviousClicked={onPreviousClicked}
                        onNextClicked={onNextClicked}
                        onSelectTipForDeletion={onSelectTipForDeletion}
                      />
                    </div>
                  </div>
                ) : (
                  <section className="hero is-medium has-background-white-ter">
                    <div className="hero-body">
                      <p className="title">
                        <FontAwesomeIcon className="fas" icon={faTable} />
                        &nbsp;No Tips Available
                      </p>
                      <p className="subtitle">
                        No tips have been created yet.
                        <b>
                          <Link to="/admin/tips/add">
                            &nbsp;Click here&nbsp;
                            <FontAwesomeIcon
                              className="mdi"
                              icon={faArrowRight}
                            />
                          </Link>
                        </b>
                        &nbsp;to create a new tip.
                      </p>
                    </div>
                  </section>
                )}
              </>
            )}

            <div class="columns pt-5">
              <div class="column is-half">
                <Link
                  class="button is-fullwidth-mobile"
                  to={`/admin/dashboard`}
                >
                  <FontAwesomeIcon className="fas" icon={faArrowLeft} />
                  &nbsp;Back to Dashboard
                </Link>
              </div>
            </div>
          </nav>
        </section>
      </div>
    </>
  );
}

export default AdminTipsList;
