import React, {useState, useEffect} from "react";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faDumbbell,
	faRefresh,
	faFilter,
	faSearch,
	faTable,
	faGauge,
	faTicketSimple,
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useRecoilState} from "recoil";

import Layout from "../../Menu/Layout";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import FormSelectField from "../../Reusable/FormSelectField";
import FormMultiSelectFieldForTags from "../../Reusable/FormMultiSelectFieldForTags";
import {PAGE_SIZE_OPTIONS} from "../../../Constants/FieldOptions";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import MemberPreviousListDesktop from "./ListDesktop";
import MemberPreviousListMobile from "./ListMobile";
import {getTipsListAPI} from "../../../API/tips";

const MemberPreviousTipsList = () => {
	// Global State
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	// Component States
	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10); // Pagination
	const [previousCursors, setPreviousCursors] = useState([]); // Pagination
	const [nextCursor, setNextCursor] = useState(""); // Pagination
	const [currentCursor, setCurrentCursor] = useState(""); // Pagination
	const [showFilter, setShowFilter] = useState(false); // Filtering + Searching
	const [sortField, setSortField] = useState("created"); // Sorting
	const [temporarySearchText, setTemporarySearchText] = useState(""); // Searching - The search field value as your writes their query.
	const [actualSearchText, setActualSearchText] = useState(""); // Searching - The actual search query value to submit to the API.;
	const [status, setStatus] = useState("");
	const [videoType, setVideoType] = useState("");

	// Breadcrumb items
	const breadcrumbItems = {
		items: [
			{text: "Dashboard", link: "/dashboard", isActive: false, icon: faGauge},
			{text: "Previous Tips", link: "#", icon: faTicketSimple, isActive: true},
		],
		mobileBackLinkItems: {
			link: "/dashboard",
			text: "Back to Dashboard",
			icon: faArrowLeft,
		},
	};

	////
	//// API.
	////

	const onPreviousTipsListSuccess = (response) => {
		console.log("onPreviousTipsListSuccess: Starting...");
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor); // For pagination purposes.
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	};

	const onPreviousTipsListError = (apiErr) => {
		console.log("onPreviousTipsListError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	};

	const onPreviousTipsListDone = () => {
		console.log("onPreviousTipsListDone: Starting...");
		setFetching(false);
	};

	////
	//// Event handling.
	////

	// Note: currentCursor, pageSize, actualSearchText, category, movementType, status, gender, videoType, tags
	const fetchList = (cur, limit, keywords, cat, st, vt) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit); // Pagination
		params.set("sort_field", "created"); // Sorting
		params.set("sort_order", -1); // Sorting - descending, meaning most recent start date to oldest start date.

		if (cur !== "") {
			// Pagination
			params.set("cursor", cur);
		}

		// Filtering
		if (keywords !== undefined && keywords !== null && keywords !== "") {
			// Searhcing
			params.set("search", keywords);
		}
		if (cat !== undefined && cat !== null && cat !== "") {
			params.set("category", cat);
		}
		if (st !== undefined && st !== null && st !== "") {
			params.set("status", st);
		}
		if (vt !== undefined && vt !== null && vt !== "") {
			params.set("video_type", vt);
		}

		getTipsListAPI(
			params,
			onPreviousTipsListSuccess,
			onPreviousTipsListError,
			onPreviousTipsListDone
		);
	};

	const onNextClicked = (e) => {
		console.log("onNextClicked");
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		console.log("onPreviousClicked");
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = (e) => {
		// Searching
		console.log("Search button clicked...");
		setActualSearchText(temporarySearchText);
	};

	// Fetch Data on Component Mount or Change in Filters
	useEffect(() => {
		let mounted = true;
		if (mounted) {
			window.scrollTo(0, 0);
			fetchList(currentCursor, pageSize, actualSearchText, status, videoType);
		}
		return () => {
			mounted = false;
		};
	}, [currentCursor, pageSize, actualSearchText, status, videoType]);

	return (
		<Layout breadcrumbItems={breadcrumbItems}>
			<div className="box">
				<div className="columns">
					<div className="column">
						<h1 className="title is-4">
							<FontAwesomeIcon className="fas" icon={faTicketSimple} />
							&nbsp;Previous Tips
						</h1>
					</div>
					<div className="column has-text-right">
						<button
							onClick={() =>
								fetchList(
									currentCursor,
									pageSize,
									actualSearchText,
									status,
									videoType
								)
							}
							className="button is-link is-small"
							type="button">
							<FontAwesomeIcon className="mdi" icon={faRefresh} />
							&nbsp;Refresh
						</button>
						&nbsp;
						<button
							onClick={() => setShowFilter(!showFilter)}
							className="button is-primary is-small"
							type="button">
							<FontAwesomeIcon className="mdi" icon={faFilter} />
							&nbsp;Filter
						</button>
					</div>
				</div>

				{showFilter && (
					<div
						className="columns has-background-white-bis"
						style={{borderRadius: "15px", padding: "20px"}}>
						<div className="column">
							<FormInputFieldWithButton
								label={"Search"}
								name="temporarySearchText"
								type="text"
								placeholder="Search by name"
								value={temporarySearchText}
								onChange={(e) => setTemporarySearchText(e.target.value)}
								isRequired={true}
								maxWidth="100%"
								buttonLabel={
									<FontAwesomeIcon className="fas" icon={faSearch} />
								}
								onButtonClick={() => setActualSearchText(temporarySearchText)}
							/>
						</div>
					</div>
				)}

				{isFetching ? (
					<PageLoadingContent displayMessage={"Please wait..."} />
				) : (
					<>
						<FormErrorBox errors={errors} />
						{listData &&
						listData.results &&
						(listData.results.length > 0 || previousCursors.length > 0) ? (
							<>
								{listData.results.map((tip) => (
									<div key={tip.id}>
										{/* Desktop version */}
										<div className="is-hidden-touch">
											<MemberPreviousListDesktop
												name={tip.name}
												description={tip.description}
												thumbnailUrl={tip.thumbnailUrl}
												thumbnailObjectUrl={tip.thumbnailObjectUrl}
												thumbnailType={tip.thumbnailType}
												id={tip.id}
											/>
										</div>
										{/* Mobile version */}
										<div className="is-fullwidth is-hidden-desktop">
											<MemberPreviousListMobile
												name={tip.name}
												description={tip.description}
												thumbnailUrl={tip.thumbnailUrl}
												thumbnailObjectUrl={tip.thumbnailObjectUrl}
												thumbnailType={tip.thumbnailType}
												id={tip.id}
											/>
										</div>
									</div>
								))}
								<div className="columns">
									<div className="column is-half">
										<span className="select">
											<select
												className="input has-text-grey-light"
												name="pageSize"
												onChange={(e) => setPageSize(parseInt(e.target.value))}>
												{PAGE_SIZE_OPTIONS.map((option) => (
													<option
														key={option.value}
														value={option.value}
														selected={pageSize === option.value}>
														{option.label}
													</option>
												))}
											</select>
										</span>
									</div>
									<div className="column is-half has-text-right">
										{previousCursors.length > 0 && (
											<button className="button" onClick={onPreviousClicked}>
												Previous
											</button>
										)}
										{listData.hasNextPage && (
											<button className="button" onClick={onNextClicked}>
												Next
											</button>
										)}
									</div>
								</div>
							</>
						) : (
							<section className="hero is-medium has-background-white-ter">
								<div className="hero-body">
									<p className="title">
										<FontAwesomeIcon className="fas" icon={faTable} />
										&nbsp;No Previous Tips
									</p>
									<p className="subtitle">
										No previous tips found at the moment. Please check back
										later!
									</p>
								</div>
							</section>
						)}
					</>
				)}
			</div>
		</Layout>
	);
};

export default MemberPreviousTipsList;
