import {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faGauge,
	faEye,
	faBolt,
	faPencilRuler,
	faUsers,
	faMedal,
	faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";

import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {topAlertMessageState, topAlertStatusState} from "../../../AppState";
import {
	deletefitnessChallengeAPI,
	getfitnessChallengeDetailAPI,
} from "../../../API/FitnessChallenge";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import Layout from "../../Menu/Layout";

function MemberFitnessChallengeDetail() {
	// URL Parameters
	const {id} = useParams();

	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);

	// Component states
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [selectedChallengeForDeletion, setSelectedChallengeForDeletion] =
		useState(null);

	// Event handling
	const onDeleteConfirmButtonClick = () => {
		deletefitnessChallengeAPI(id, onDeleteSuccess, onDeleteError, onDeleteDone);
		setSelectedChallengeForDeletion(null);
	};

	// API Success and Error Handlers
	const onChallengeDetailSuccess = (response) => {
		setDatum(response);
	};

	const onChallengeDetailError = (apiErr) => {
		setErrors(apiErr);
		Scroll.animateScroll.scrollToTop();
	};

	const onChallengeDetailDone = () => {
		setFetching(false);
	};

	const onDeleteSuccess = () => {
		setTopAlertStatus("success");
		setTopAlertMessage("Challenge successfully removed.");
		setTimeout(() => setTopAlertMessage(""), 2000);
		setForceURL("/fitness-challenge");
	};

	const onDeleteError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Unable to delete the challenge.");
		setTimeout(() => setTopAlertMessage(""), 2000);
		Scroll.animateScroll.scrollToTop();
	};

	const onDeleteDone = () => {
		setFetching(false);
	};

	// Fetch challenge details on mount
	useEffect(() => {
		window.scrollTo(0, 0);
		setFetching(true);
		getfitnessChallengeDetailAPI(
			id,
			onChallengeDetailSuccess,
			onChallengeDetailError,
			onChallengeDetailDone
		);
	}, [id]);

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout
			breadcrumbItems={{
				items: [
					{
						text: "Dashboard",
						link: "/dashboard",
						isActive: false,
						icon: faGauge,
					},
					{
						text: "Fitness Challenges",
						link: "/fitness-challenge",
						isActive: false,
						icon: faBolt,
					},
					{text: "Details", link: "#", isActive: true, icon: faEye},
				],
				mobileBackLinkItems: {
					link: "/dashboard",
					text: "Return to Dashboard",
					icon: faArrowLeft,
				},
			}}>
			{/* Delete modal */}
			<div
				className={`modal ${selectedChallengeForDeletion ? "is-active" : ""}`}>
				<div className="modal-background"></div>
				<div className="modal-card">
					<header className="modal-card-head">
						<p className="modal-card-title">Confirm Deletion</p>
						<button
							className="delete"
							aria-label="close"
							onClick={() => setSelectedChallengeForDeletion(null)}></button>
					</header>
					<section className="modal-card-body">
						Are you sure you want to remove this challenge? This action cannot
						be undone.
					</section>
					<footer className="modal-card-foot">
						<button
							className="button is-success"
							onClick={onDeleteConfirmButtonClick}>
							Yes, Delete
						</button>
						<button
							className="button"
							onClick={() => setSelectedChallengeForDeletion(null)}>
							Cancel
						</button>
					</footer>
				</div>
			</div>

			{/* Page Content */}
			<div class="box">
				{/* Title + Options */}
				{datum && (
					<div class="columns">
						<div class="column">
							<p class="title is-4">
								<FontAwesomeIcon className="fas" icon={faBolt} />
								&nbsp;Challenges
							</p>
						</div>
					</div>
				)}

				{/* Tab Navigation */}
				<div class="tabs is-medium is-size-7-mobile">
					<ul>
						<li class="is-active">
							<Link>
								<strong>Detail</strong>
							</Link>
						</li>
						{datum.category === 2 && (
							<li class="">
								<Link
									to={`/fitness-challenge/${id}/points`}
									aria-current="page">
									<strong>Points</strong>
								</Link>
							</li>
						)}
						<li class="">
							<Link to={`/fitness-challenge/${id}/leaderboard`}>
								<strong>LeaderBoard</strong>
							</Link>
						</li>
					</ul>
				</div>
				{isFetching ? (
					<PageLoadingContent displayMessage={"Loading challenge details..."} />
				) : (
					<>
						<div className="columns">
							<div className="column">
								<h2 className="title is-4 mb-3">{datum?.name}</h2>

								{/* Challenge Info */}
								<DataDisplayRowText
									label="Challenge Overview"
									value={datum.description}
								/>
								<DataDisplayRowText
									label="Duration"
									value={`${datum.durationInWeeks} weeks`}
								/>
								<DataDisplayRowText
									label="Start Date"
									value={datum.startTime}
									type="datetime"
								/>
								<DataDisplayRowText
									label="Maximum Points Required to complete the challenge"
									value={datum.maxPoints}
								/>
								<DataDisplayRowText
									label="Challenge Type"
									value={
										datum.category === 2
											? "Self Update Based"
											: "Biometric Based"
									}
								/>

								{/* Badge Info */}
								{/* Badge Info */}
								{datum.earnsBadge && (
									<div
										className="box has-text-centered p-5 mt-4"
										style={{
											background:
												"linear-gradient(to right, #C14C00, #c14d0080)",
											color: "#fff",
											borderRadius: "12px",
										}}>
										<p className="subtitle is-5 has-text-info has-text-weight-bold">
											<FontAwesomeIcon className="fas" icon={faMedal} />
											&nbsp;Congratulations! You’re on your way to earning a
											badge!
										</p>
										<div className="box has-background-white-ter p-4">
											<h3 className="title is-5 has-text-weight-bold has-text-info">
												<FontAwesomeIcon className="fas" icon={faTrophy} />
												&nbsp;Earn the{" "}
												<span className="has-text-weight-bold">
													{datum.badgeName} Badge!
												</span>
											</h3>
											<p className="subtitle py-1 is-6 has-text-grey-dark">
												Completing this challenge will unlock the exclusive{" "}
												<span className="has-text-weight-bold">
													{datum.badgeName}
												</span>{" "}
												badge. Show off your achievement and keep pushing your
												limits!
											</p>
											<figure className="image is-flex is-justify-content-center is-128x128 is-responsive mx-auto">
												<img
													style={{maxHeight: "104px", width: "auto"}}
													src={datum?.badgeDetails?.thumbnailUrl}
													alt={`${datum.badgeName} badge`}
												/>
											</figure>

											{/* Different messages based on badge criteria */}
											{datum.criteria === 1 ? (
												<p>
													Complete the challenge to automatically{" "}
													<span className="has-text-weight-bold">
														claim your {datum.badgeName} badge
													</span>{" "}
													as a testament to your dedication!
												</p>
											) : (
												<p>
													Complete the challenge and your badge will be{" "}
													<span className="has-text-weight-bold">
														manually assigned by an admin
													</span>
													. Keep an eye out for your {datum.badgeName} badge
													once it's awarded!
												</p>
											)}
										</div>
									</div>
								)}

								{/* Rules Section */}
								<p className="subtitle is-6 mt-3">
									<FontAwesomeIcon className="fas" icon={faPencilRuler} />
									&nbsp;Challenge Rules
								</p>
								{datum?.rules?.map((rule, index) => (
									<div className="box" key={index}>
										<h3 className="title is-5 mb-3">
											Rule {index + 1}: {rule.name}
										</h3>
										<DataDisplayRowText
											label="Details"
											value={rule.description}
										/>
									</div>
								))}

								{/* Members Section */}
								<p className="subtitle is-6 mt-3">
									<FontAwesomeIcon className="fas" icon={faUsers} />
									&nbsp;Participants
								</p>
								<div className="content">
									<ul>
										{datum?.users?.map((user, index) => (
											<li key={index}>
												{user.name} -{" "}
												{user.completed ? (
													<button class="button is-small is-responsive is-success">
														Completed
													</button>
												) : (
													<button class="button is-small is-responsive is-warning">
														In Progress
													</button>
												)}
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
						<FormErrorBox errors={errors} />
					</>
				)}
			</div>
		</Layout>
	);
}

export default MemberFitnessChallengeDetail;
