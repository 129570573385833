import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCheckCircle,
  faPencil,
  faArrowLeft,
  faGauge,
  faRibbon,
  faEye,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState } from "../../../AppState";
import { deleteBadgeAPI, getBadgeDetailAPI } from "../../../API/badge";
import Layout from "../../Menu/Layout";
import { BADGE_STATUS_MAP } from "../../../Constants/FieldOptions";

function AdminBadgeDetail() {
  //// URL Parameters
  const { id } = useParams();

  //// Global state
  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);

  //// Component states
  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [badge, setBadge] = useState({});
  const [selectedBadgeForDeletion, setSelectedBadgeForDeletion] =
    useState(null);

  // Breadcrumb items
  const breadcrumbItems = {
    items: [
      {
        text: "Dashboard",
        link: "/admin/dashboard",
        isActive: false,
        icon: faGauge,
      },
      {
        text: "Badges",
        link: "/admin/badges",
        isActive: false,
        icon: faRibbon,
      },
      { text: "Detail", link: "#", isActive: true, icon: faEye },
    ],
  };

  //// Event handling
  const onDeleteConfirmButtonClick = () => {
    deleteBadgeAPI(
      selectedBadgeForDeletion.id,
      onBadgeDeleteSuccess,
      onBadgeDeleteError,
      onBadgeDeleteDone
    );
    setSelectedBadgeForDeletion(null);
  };

  //// API
  const onBadgeDetailSuccess = (response) => {
    setBadge(response);
  };

  const onBadgeDetailError = (apiErr) => {
    setErrors(apiErr);
    Scroll.animateScroll.scrollToTop();
  };

  const onBadgeDetailDone = () => {
    setFetching(false);
  };

  const onBadgeDeleteSuccess = () => {
    setTopAlertStatus("success");
    setTopAlertMessage("Badge deleted");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    setForceURL("/admin/badges");
  };

  const onBadgeDeleteError = (apiErr) => {
    setErrors(apiErr);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed to delete badge");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    Scroll.animateScroll.scrollToTop();
  };

  const onBadgeDeleteDone = () => {
    setFetching(false);
  };

  //// Misc
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on mount
    setFetching(true);
    getBadgeDetailAPI(
      id,
      onBadgeDetailSuccess,
      onBadgeDetailError,
      onBadgeDetailDone
    );
  }, [id]);

  //// Component rendering
  if (forceURL) {
    return <Navigate to={forceURL} />;
  }

  return (
    <Layout breadcrumbItems={breadcrumbItems}>
      {/* Page */}
      <div className="box">
        <div className="columns">
          <div className="column">
            <p className="title is-4">
              <FontAwesomeIcon icon={faRibbon} />
              &nbsp;Badge
            </p>
          </div>
          <div className="column has-text-right">
            <Link
              to={`/admin/badges/${id}/edit`}
              className="button is-warning is-small"
            >
              <FontAwesomeIcon icon={faPencil} />
              &nbsp;Edit
            </Link>
            &nbsp;
            <button
              className="button is-danger is-small"
              onClick={() => setSelectedBadgeForDeletion(badge)}
            >
              <FontAwesomeIcon icon={faTrash} />
              &nbsp;Delete
            </button>
          </div>
        </div>
        <FormErrorBox errors={errors} />
        {isFetching ? (
          <PageLoadingContent displayMessage={"Please wait..."} />
        ) : (
          <>
            <div className="content">
              <p>
                <strong>Name:</strong> {badge.name}
              </p>
              <p>
                <strong>Description:</strong> {badge.description}
              </p>
              <p>
                <strong>Criteria:</strong> {BADGE_STATUS_MAP[badge.criteria]}
              </p>
              <p>
                <strong>Image:</strong>{" "}
                {badge.thumbnailUrl ? (
                  <figure className="image is-128x128">
                    <img src={badge.thumbnailUrl} alt="Badge Thumbnail" />
                  </figure>
                ) : (
                  <p>No thumbnail available.</p>
                )}
              </p>
            </div>

            {/* Modal for delete confirmation */}
            {selectedBadgeForDeletion && (
              <div className={`modal is-active`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                  <header className="modal-card-head">
                    <p className="modal-card-title">Confirm Deletion</p>
                    <button
                      className="delete"
                      aria-label="close"
                      onClick={() => setSelectedBadgeForDeletion(null)}
                    ></button>
                  </header>
                  <section className="modal-card-body">
                    Are you sure you want to delete the badge "{badge.name}"?
                    This action cannot be undone.
                  </section>
                  <footer className="modal-card-foot">
                    <button
                      className="button is-danger"
                      onClick={onDeleteConfirmButtonClick}
                    >
                      Confirm
                    </button>
                    <button
                      className="button"
                      onClick={() => setSelectedBadgeForDeletion(null)}
                    >
                      Cancel
                    </button>
                  </footer>
                </div>
              </div>
            )}

            <div className="columns pt-5">
              <div className="column is-half">
                <Link className="button is-fullwidth-mobile" to="/admin/badges">
                  <FontAwesomeIcon icon={faArrowLeft} />
                  &nbsp;Back to Badges
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default AdminBadgeDetail;
