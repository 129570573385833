import React from "react";
import PropTypes from "prop-types";

const ProgressBar = ({ currentLevel, currentLevelDetails }) => {
  const progress = currentLevel?.challengesCompleted || 0;
  const totalChallenges = currentLevelDetails?.numChallenges || 1;
  const progressPercentage = (progress / totalChallenges) * 100;

  const getMotivationalText = () => {
    if (progress === 0) {
      return "Time to get started! Your journey begins now. 💪";
    } else if (progress > 0 && progress <= totalChallenges * 0.25) {
      return "You're off to a great start! Keep that momentum going! 🌟";
    } else if (progress > totalChallenges * 0.25 && progress < totalChallenges * 0.5) {
      return "You're making progress! Stay focused, you're doing awesome! 🔥";
    } else if (progress >= totalChallenges * 0.5 && progress < totalChallenges * 0.75) {
      return "Halfway there! You’ve got this, keep pushing! 🏃‍♂️🏃‍♀️";
    } else if (progress >= totalChallenges * 0.75 && progress < totalChallenges) {
      return "Keep going, you're almost there! The finish line is in sight! 🚀";
    } else if (progress === totalChallenges) {
      return "Great job! On to the next challenge! 🏆";
    } else {
      return "Amazing work! You've surpassed expectations! 🌟";
    }
  };

  return (
    <div className="level-info-container">
      <p className="level-text">
        {currentLevelDetails?.name} - {progress}/{totalChallenges}
      </p>
      <div className="progress-bar">
        <div className="progress-bar-fill" style={{ width: `${progressPercentage}%` }}></div>
      </div>
      <p className="motivational-text">{getMotivationalText()}</p>
    </div>
  );
};

ProgressBar.propTypes = {
  currentLevel: PropTypes.object.isRequired,
  currentLevelDetails: PropTypes.object.isRequired,
};

export default ProgressBar;
