import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faPlus,
  faArrowLeft,
  faGauge,
  faVideoCamera,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormSelectField from "../../Reusable/FormSelectField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState } from "../../../AppState";
import {
  EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
  EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL,
  EXERCISE_VIDEO_TYPE_VIMEO,
  EXERCISE_VIDEO_TYPE_YOUTUBE,
  EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
} from "../../../Constants/App";
import { postTipCreateAPI } from "../../../API/tips";
import { TIPS_STATUS_WITH_EMPTY_OPTIONS } from "../../../Constants/FieldOptions";
import Layout from "../../Menu/Layout";

function AdminTipAdd() {
  const [, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [, setTopAlertStatus] = useRecoilState(topAlertStatusState);

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [thumbnailType, setThumbnailType] = useState(0);
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
  const [thumbnailAttachmentName, setThumbnailAttachmentName] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(0);
  const [videoType, setVideoType] = useState(0);
  const [videoURL, setVideoURL] = useState("");
  const [videoAttachmentID, setVideoAttachmentID] = useState("");
  const [videoAttachmentName, setVideoAttachmentName] = useState("");

  // Breadcrumb items
  const breadcrumbItems = {
    items: [
      { text: "Dashboard", link: "/admin/dashboard", isActive: false, icon: faGauge },
      { text: "Tips", link: "/admin/tips", isActive: false, icon: faVideoCamera },
      { text: "New", link: "#", isActive: true, icon: faPlus }
    ]
  };

  ////
  //// Event handling.
  ////

  const onSubmitClick = (e) => {
    console.log("onSubmitClick: Starting...");
    setFetching(true);
    setErrors({});
    postTipCreateAPI(
      {
        thumbnail_type: thumbnailType,
        thumbnail_upload: thumbnailAttachmentID,
        thumbnail_url: thumbnailURL,
        name: name,
        description: description,
        video_type: videoType,
        video_upload: videoAttachmentID,
        video_url: videoURL,
        status: status,
      },
      onAddSuccess,
      onAddError,
      onAddDone
    );
    console.log("onSubmitClick: Finished.");
  };

  function onAddSuccess(response) {
    setTopAlertMessage("Tip created successfully");
    setTopAlertStatus("success");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    setForceURL("/admin/tips/");
  }

  function onAddError(apiErr) {
    setErrors(apiErr);
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onAddDone() {
    setFetching(false);
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.
    }

    return () => {
      mounted = false;
    };
  }, []);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <Layout breadcrumbItems={breadcrumbItems}>

          {/* Modal */}
          {/* Nothing ... */}

          {/* Page */}
          <div class="box">
          <p className="title is-4">
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;Add Tips
          </p>
            <FormErrorBox errors={errors} />

            <p class="pb-4 mb-5 has-text-grey">
              Please fill out all the required fields before submitting this
              form.
            </p>

            {isFetching ? (
              <PageLoadingContent displayMessage={"Please wait..."} />
            ) : (
              <>
                  <FormInputField
                    name="name"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    isRequired={true}
                    maxWidth="380px"
                    label="Name"
                    errorText={errors && errors.name}
                  />

                  <FormTextareaField
                    rows={2}
                    name="description"
                    placeholder=""
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    isRequired={true}
                    maxWidth="380px"
                    label="Description"
                    errorText={errors && errors.description}
                  />

                  <FormSelectField
                    name="status"
                    label="Status"
                    errorText={errors && errors.status}
                    options={TIPS_STATUS_WITH_EMPTY_OPTIONS}
                    maxWidth="380px"
                    selectedValue={status}
                    onChange={(e) => setStatus(parseInt(e.target.value))}
                  />
                  {/*
                                        ------------------------
                                        THUMBNAIL UPLOAD SECTION
                                        ------------------------
                                    */}
                  <p class="subtitle is-6">
                    <FontAwesomeIcon className="fas" icon={faImage} />
                    &nbsp;Thumbnail
                  </p>
                  <hr />

                  <FormRadioField
                    label="Thumbnail Type"
                    name="thumbnailType"
                    placeholder="Pick"
                    value={thumbnailType}
                    opt1Value={EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE}
                    opt1Label="File Upload"
                    opt2Value={EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL}
                    opt2Label="External URL"
                    errorText={errors && errors.thumbnailType}
                    onChange={(e) => setThumbnailType(parseInt(e.target.value))}
                    maxWidth="180px"
                    disabled={false}
                  />

                  {(() => {
                    switch (thumbnailType) {
                      case EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE:
                        return (
                          <>
                            <FormAttachmentField
                              label="File Upload"
                              name="thumbnaiUpload"
                              placeholder="Upload file"
                              errorText={errors && errors.thumbnailUpload}
                              attachmentID={thumbnailAttachmentID}
                              setAttachmentID={setThumbnailAttachmentID}
                              attachmentFilename={thumbnailAttachmentName}
                              setAttachmentFilename={setThumbnailAttachmentName}
                            />
                          </>
                        );
                      case EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL:
                        return (
                          <FormInputField
                            label="Thumbnail External URL"
                            name="thumbnailUrl"
                            placeholder="URL input"
                            value={thumbnailURL}
                            errorText={errors && errors.thumbnailUrl}
                            helpText=""
                            onChange={(e) => setThumbnailURL(e.target.value)}
                            isRequired={true}
                            maxWidth="100%"
                          />
                        );
                      default:
                        return null;
                    }
                  })()}

                  {/* video upload section */}
                  <p class="subtitle is-6">
                    <FontAwesomeIcon className="fas" icon={faVideo} />
                    &nbsp;Video
                  </p>
                  <hr />

                  <FormRadioField
                    label="Video Type"
                    name="videoType"
                    placeholder="Pick"
                    value={videoType}
                    opt1Value={EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE}
                    opt1Label="File Upload"
                    opt2Value={EXERCISE_VIDEO_TYPE_YOUTUBE}
                    opt2Label="YouTube"
                    opt3Value={EXERCISE_VIDEO_TYPE_VIMEO}
                    opt3Label="Vimeo"
                    errorText={errors && errors.videoType}
                    onChange={(e) => setVideoType(parseInt(e.target.value))}
                    maxWidth="180px"
                    disabled={false}
                  />

                  {(() => {
                    switch (videoType) {
                      case EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE:
                        return (
                          <>
                            <FormAttachmentField
                              label="Video Upload"
                              name="videoUpload"
                              placeholder="Upload file"
                              errorText={errors && errors.videoUpload}
                              attachmentID={videoAttachmentID}
                              setAttachmentID={setVideoAttachmentID}
                              attachmentFilename={videoAttachmentName}
                              setAttachmentFilename={setVideoAttachmentName}
                            />
                          </>
                        );
                      case EXERCISE_VIDEO_TYPE_YOUTUBE:
                        return (
                          <FormInputField
                            label="YouTube URL"
                            name="videoExternalURL"
                            placeholder="URL input"
                            value={videoURL}
                            errorText={errors && errors.videoUrl}
                            helpText=""
                            onChange={(e) => setVideoURL(e.target.value)}
                            isRequired={true}
                            maxWidth="380px"
                          />
                        );
                      case EXERCISE_VIDEO_TYPE_VIMEO:
                        return (
                          <FormInputField
                            label="Vimeo URL"
                            name="videoExternalURL"
                            placeholder="URL input"
                            value={videoURL}
                            errorText={errors && errors.videoUrl}
                            helpText=""
                            onChange={(e) => setVideoURL(e.target.value)}
                            isRequired={true}
                            maxWidth="380px"
                          />
                        );
                      default:
                        return null;
                    }
                  })()}

                  <div class="columns pt-5">
                    <div class="column is-half">
                      <Link
                        class="button is-fullwidth-mobile"
                        to={`/admin/tips`}
                      >
                        <FontAwesomeIcon className="fas" icon={faArrowLeft} />
                        &nbsp;Back to Tips
                      </Link>
                    </div>
                    <div class="column is-half has-text-right">
                      <button
                        onClick={onSubmitClick}
                        class="button is-success is-fullwidth-mobile"
                        type="button"
                      >
                        <FontAwesomeIcon className="fas" icon={faPlus} />
                        &nbsp;Submit
                      </button>
                    </div>
                  </div>
              </>
            )}
          </div>
        </Layout>
  );
}

export default AdminTipAdd;
