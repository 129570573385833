import React, {useState, useEffect} from "react";
import Scroll from "react-scroll";
import {useRecoilState} from "recoil";
import {useParams, Link, Navigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faDumbbell,
	faUser,
	faHeart,
	faGauge,
	faCog,
	faPlusCircle,
	faSearch,
	faTicketSimple,
} from "@fortawesome/free-solid-svg-icons";
import Vimeo from "@u-wave/react-vimeo";

import Layout from "../../Menu/Layout";
import {getExerciseDetailAPI} from "../../../API/Exercise";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {topAlertMessageState, topAlertStatusState} from "../../../AppState";
import {
	EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL,
	EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_VIDEO_TYPE_YOUTUBE,
	EXERCISE_VIDEO_TYPE_VIMEO,
} from "../../../Constants/App";
import {VideoPlayer} from "../Exercise/Detail";
import { getTipDetailAPI } from "../../../API/tips";

////
//// Custom Component
////

const VideoDescription = ({description}) => (
	<div>
		<p class="is-size-6 has-text-black has-text-weight-semibold">{description}</p>
	</div>
);

const MemberPreviousTipsDetail = () => {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [tabIndex, setTabIndex] = useState(1);
	const [selectedExerciseForDeletion, setSelectedExerciseForDeletion] =
		useState(null);

	////
	//// BREADCRUMB
	////
	const breadcrumbItems = {
		items: [
			{text: "Dashboard", link: "/dashboard", isActive: false, icon: faGauge},
			{
				text: "Previous Week Tips",
				link: "#",
				icon: faTicketSimple,
				isActive: true,
			},
		],
		mobileBackLinkItems: {
			link: "/dashboard",
			text: "Back to Dashboard",
			icon: faArrowLeft,
		},
	};

	////
	//// API.
	////

	// --- Detail --- //

	function onPreviousTipsDetailSuccess(response) {
		console.log("onPreviousTipsDetailSuccess: Starting...");
		setDatum(response);
	}

	function onPreviousTipsDetailError(apiErr) {
		console.log("onPreviousTipsDetailError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onPreviousTipsDetailDone() {
		console.log("onPreviousTipsDetailDone: Starting...");
		setFetching(false);
	}

	////
	//// Event handling.
	////

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.

			setFetching(true);
			getTipDetailAPI(
				id,
				onPreviousTipsDetailSuccess,
				onPreviousTipsDetailError,
				onPreviousTipsDetailDone
			);
			
		}

		return () => {
			mounted = false;
		};
	}, []);

	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems}>
			<div className="box">
				<div class="columns is-flex-direction-column">
					<div className="column is-12">
						<VideoPlayer
							name={datum.name}
							videoType={datum.videoType}
							videoUrl={datum.videoUrl}
							videoObjectUrl={datum.videoObjectUrl}
							thumbnailType={datum.thumbnailType}
							thumbnailUrl={datum.thumbnailUrl}
							thumbnailObjectUrl={datum.thumbnailObjectUrl}
							tagsHidden={false}
						/>
					</div>
					<div className="column is-12">
						<VideoDescription description={datum.description} />
					</div>
				</div>
				<div className="columns pt-5 pb-4">
					<div className="column">
						<div className="column is-3 has-text-right p-0">
							<Link to="/previous-week-tips" aria-current="page">
								<button className="button is-fullwidth">
									<FontAwesomeIcon className="fas" icon={faArrowLeft} />
									&nbsp;Back to Previous Tips
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default MemberPreviousTipsDetail;
