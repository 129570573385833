import React, { useState, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faArrowLeft,
  faGauge,
  faLightbulb,
  faEye,
  faPencil,
  faCheckCircle,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormSelectField from "../../Reusable/FormSelectField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState } from "../../../AppState";
import {
  EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
  EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL,
  EXERCISE_VIDEO_TYPE_VIMEO,
  EXERCISE_VIDEO_TYPE_YOUTUBE,
  EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
} from "../../../Constants/App";
import { getTipDetailAPI, putTipUpdateAPI } from "../../../API/tips";
import { TIPS_STATUS_WITH_EMPTY_OPTIONS } from "../../../Constants/FieldOptions";

function AdminTipUpdate() {
  ////
  //// URL Parameters.
  ////

  const { id } = useParams();

  const [, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [, setTopAlertStatus] = useRecoilState(topAlertStatusState);

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [thumbnailType, setThumbnailType] = useState(0);
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
  const [thumbnailAttachmentFilename, setThumbnailAttachmentFilename] =
    useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(0);
  const [videoType, setVideoType] = useState(0);
  const [videoURL, setVideoURL] = useState("");
  const [videoAttachmentID, setVideoAttachmentID] = useState("");
  const [videoAttachmentFilename, setVideoAttachmentFilename] = useState("");

  ////
  //// Event handling.
  ////

  const onSubmitClick = (e) => {
    setFetching(true);
    setErrors({});
    putTipUpdateAPI(
      {
        id: id,
        video_type: videoType,
        video_upload: videoAttachmentID,
        video_url: videoURL,
        thumbnail_type: thumbnailType,
        thumbnail_upload: thumbnailAttachmentID,
        thumbnail_url: thumbnailURL,
        name: name,
        description: description,
        status: status,
      },
      onUpdateSuccess,
      onUpdateError,
      onUpdateDone
    );
  };

  function onUpdateSuccess(response) {
    setTopAlertMessage("Tip updated");
    setTopAlertStatus("success");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    // Redirect the organization to the organization attachments page.
    setForceURL("/admin/tips/" + response.id + "");
  }

  function onUpdateError(apiErr) {
    setErrors(apiErr);
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onUpdateDone() {
    setFetching(false);
  }

  function onDetailSuccess(response) {
    // DEVELOPERS NOTE: If zero string then that's a MongoDB empty db record so handle the code as follows.
    if (response.videoAttachmentId === "000000000000000000000000") {
      response.videoAttachmentId = "";
    } else {
      setVideoAttachmentID(response.videoAttachmentId);
    }
    if (response.thumbnailAttachmentId === "000000000000000000000000") {
      response.thumbnailAttachmentId = "";
    } else {
      setThumbnailAttachmentID(response.thumbnailAttachmentId);
    }
    setVideoType(response.videoType);
    setVideoURL(response.videoUrl);
    setVideoAttachmentFilename(response.videoAttachmentFilename);

    setThumbnailType(response.thumbnailType);
    setThumbnailURL(response.thumbnailUrl);
    setThumbnailAttachmentFilename(response.thumbnailAttachmentFilename);
    setDescription(response.description);
    setName(response.name);
    setStatus(response.status);
  }

  function onDetailError(apiErr) {
    setErrors(apiErr);

    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onDetailDone() {
    setFetching(false);
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.
      setFetching(true);
      getTipDetailAPI(id, onDetailSuccess, onDetailError, onDetailDone);
    }

    return () => {
      mounted = false;
    };
  }, [id]);

  ////
  //// Component rendering.
  ////

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  const isVideoUploaded = videoAttachmentID !== "" || videoURL !== "";
  const isThumbnailUploaded =
    thumbnailAttachmentID !== "" || thumbnailURL !== "";

  return (
    <>
      <div className="container">
        <section className="section">
          {/* Desktop Breadcrumbs */}
          <nav className="breadcrumb is-hidden-touch" aria-label="breadcrumbs">
            <ul>
              <li className="">
                <Link to="/admin/dashboard" aria-current="page">
                  <FontAwesomeIcon className="fas" icon={faGauge} />
                  &nbsp;Dashboard
                </Link>
              </li>
              <li className="">
                <Link to="/admin/tips" aria-current="page">
                  <FontAwesomeIcon className="fas" icon={faLightbulb} />
                  &nbsp;Tips
                </Link>
              </li>
              <li className="">
                <Link to={`/admin/tips/${id}`} aria-current="page">
                  <FontAwesomeIcon className="fas" icon={faEye} />
                  &nbsp;Detail
                </Link>
              </li>
              <li className="is-active">
                <Link aria-current="page">
                  <FontAwesomeIcon className="fas" icon={faPencil} />
                  &nbsp;Edit
                </Link>
              </li>
            </ul>
          </nav>

          {/* Mobile Breadcrumbs */}
          <nav
            className="breadcrumb is-hidden-desktop"
            aria-label="breadcrumbs"
          >
            <ul>
              <li className="">
                <Link to={`/admin/tips/${id}`} aria-current="page">
                  <FontAwesomeIcon className="fas" icon={faArrowLeft} />
                  &nbsp;Back to Detail
                </Link>
              </li>
            </ul>
          </nav>

          {/* Modal */}
          {/* Nothing ... */}

          {/* Page */}
          <nav className="box">
            <div className="columns">
              <div className="column">
                <p className="title is-4">
                  <FontAwesomeIcon className="fas" icon={faLightbulb} />
                  &nbsp;Tips
                </p>
              </div>
              <div className="column has-text-right"></div>
            </div>
            <FormErrorBox errors={errors} />

            <p className="pb-4 mb-5 has-text-grey">
              Please fill out all the required fields before submitting this
              form.
            </p>

            {isFetching ? (
              <PageLoadingContent displayMessage={"Please wait..."} />
            ) : (
              <>
                <div className="container">
                  <FormInputField
                    name="name"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    isRequired={true}
                    maxWidth="380px"
                    label="Name"
                    errorText={errors && errors.name}
                  />

                  <FormTextareaField
                    rows={2}
                    name="description"
                    placeholder=""
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    isRequired={true}
                    maxWidth="380px"
                    label="Description"
                    errorText={errors && errors.description}
                  />
                  <FormSelectField
                    name="status"
                    label="Status"
                    errorText={errors && errors.status}
                    options={TIPS_STATUS_WITH_EMPTY_OPTIONS}
                    maxWidth="380px"
                    selectedValue={status}
                    onChange={(e) => setStatus(parseInt(e.target.value))}
                  />

                  {/*
                                        ------------------------
                                        THUMBNAIL UPLOAD SECTION
                                        ------------------------
                                    */}
                  <p className="subtitle is-6">
                    <FontAwesomeIcon className="fas" icon={faImage} />
                    &nbsp;Thumbnail
                  </p>
                  <hr />

                  <FormRadioField
                    label="Thumbnail Type"
                    name="thumbnailType"
                    placeholder="Pick"
                    value={thumbnailType}
                    opt1Value={EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE}
                    opt1Label="File Upload"
                    opt2Value={EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL}
                    opt2Label="External URL"
                    errorText={errors && errors.thumbnailType}
                    onChange={(e) => setThumbnailType(parseInt(e.target.value))}
                    maxWidth="180px"
                    disabled={false}
                  />

                  {(() => {
                    switch (thumbnailType) {
                      case EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE:
                        return (
                          <>
                            <FormAttachmentField
                              label="File Upload"
                              name="thumbnaiUpload"
                              placeholder="Upload file"
                              errorText={errors && errors.thumbnailUpload}
                              attachmentID={thumbnailAttachmentID}
                              setAttachmentID={setThumbnailAttachmentID}
                              attachmentFilename={thumbnailAttachmentFilename}
                              setAttachmentFilename={
                                setThumbnailAttachmentFilename
                              }
                            />
                          </>
                        );
                      case EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL:
                        return (
                          <FormInputField
                            label="Thumbnail External URL"
                            name="thumbnailUrl"
                            placeholder="URL input"
                            value={thumbnailURL}
                            errorText={errors && errors.thumbnailUrl}
                            helpText=""
                            onChange={(e) => setThumbnailURL(e.target.value)}
                            isRequired={true}
                            maxWidth="380px"
                          />
                        );
                      default:
                        return null;
                    }
                  })()}

                  {/* video upload section */}
                  <p class="subtitle is-6">
                    <FontAwesomeIcon className="fas" icon={faVideo} />
                    &nbsp;Video
                  </p>
                  <hr />

                  <FormRadioField
                    label="Video Type"
                    name="videoType"
                    placeholder="Pick"
                    value={videoType}
                    opt1Value={EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE}
                    opt1Label="File Upload"
                    opt2Value={EXERCISE_VIDEO_TYPE_YOUTUBE}
                    opt2Label="YouTube"
                    opt3Value={EXERCISE_VIDEO_TYPE_VIMEO}
                    opt3Label="Vimeo"
                    errorText={errors && errors.videoType}
                    onChange={(e) => setVideoType(parseInt(e.target.value))}
                    maxWidth="180px"
                    disabled={false}
                  />

                  {(() => {
                    switch (videoType) {
                      case EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE:
                        return (
                          <>
                            <FormAttachmentField
                              label="Video Upload"
                              name="videoUpload"
                              placeholder="Upload file"
                              errorText={errors && errors.videoUpload}
                              attachmentID={videoAttachmentID}
                              setAttachmentID={setVideoAttachmentID}
                              attachmentFilename={videoAttachmentFilename}
                              setAttachmentFilename={setVideoAttachmentFilename}
                            />
                          </>
                        );
                      case EXERCISE_VIDEO_TYPE_YOUTUBE:
                        return (
                          <FormInputField
                            label="YouTube URL"
                            name="videoExternalURL"
                            placeholder="URL input"
                            value={videoURL}
                            errorText={errors && errors.videoUrl}
                            helpText=""
                            onChange={(e) => setVideoURL(e.target.value)}
                            isRequired={true}
                            maxWidth="380px"
                          />
                        );
                      case EXERCISE_VIDEO_TYPE_VIMEO:
                        return (
                          <FormInputField
                            label="Vimeo URL"
                            name="videoExternalURL"
                            placeholder="URL input"
                            value={videoURL}
                            errorText={errors && errors.videoUrl}
                            helpText=""
                            onChange={(e) => setVideoURL(e.target.value)}
                            isRequired={true}
                            maxWidth="380px"
                          />
                        );
                      default:
                        return null;
                    }
                  })()}

                  <div className="columns pt-5">
                    <div className="column is-half">
                      <Link
                        className="button is-fullwidth-mobile"
                        to={`/admin/tips/${id}`}
                      >
                        <FontAwesomeIcon className="fas" icon={faArrowLeft} />
                        &nbsp;Back to Detail
                      </Link>
                    </div>
                    <div className="column is-half has-text-right">
                      <button
                        onClick={onSubmitClick}
                        className="button is-success is-fullwidth-mobile"
                        type="button"
                      >
                        <FontAwesomeIcon className="fas" icon={faCheckCircle} />
                        &nbsp;Save
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </nav>
        </section>
      </div>
    </>
  );
}

export default AdminTipUpdate;
