import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import {
  PAGE_SIZE_OPTIONS,
  TIPS_STATUS_MAP,
  // TIPS_TYPE_MAP,
} from "../../../Constants/FieldOptions";

function AdminTipsListDesktop(props) {
  const {
    listData,
    setPageSize,
    pageSize,
    previousCursors,
    onPreviousClicked,
    onNextClicked,
    onSelectTipForDeletion,
  } = props;

  return (
    <div className="b-table">
      <div className="table-wrapper has-mobile-cards">
        <table className="table is-fullwidth is-striped is-hoverable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Decription</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {listData &&
              listData.results &&
              listData.results.map((datum) => {
                return (
                  <tr key={`desktop_${datum.id}`}>
                    <td data-label="Name">
                      {datum.name}
                      {datum.alternateName && (
                        <span>&nbsp;{datum.alternateName}</span>
                      )}
                    </td>

                    {/* <td data-label="Type">{TIPS_TYPE_MAP[datum.type]}</td> */}
                    <td data-label="Status">{TIPS_STATUS_MAP[datum.status]}</td>
                    <td data-label="description">
                      {datum.description.length > 100
                        ? `${datum.description.slice(0, 100)}...`
                        : datum.description}
                    </td>
                    <td className="is-actions-cell">
                      <div className="buttons is-right">
                        <Link
                          to={`/admin/tips/${datum.id}`}
                          className="button is-small is-dark"
                          type="button"
                        >
                          <FontAwesomeIcon className="mdi" icon={faEye} />
                          &nbsp;View
                        </Link>
                        <button
                          onClick={(e) => onSelectTipForDeletion(e, datum)}
                          className="button is-small is-danger"
                          type="button"
                        >
                          <FontAwesomeIcon className="mdi" icon={faTrashCan} />
                          &nbsp;Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className="columns">
          <div className="column is-half">
            <span className="select">
              <select
                className={`input has-text-grey-light`}
                name="pageSize"
                onChange={(e) => setPageSize(parseInt(e.target.value))}
              >
                {PAGE_SIZE_OPTIONS.map((option) => {
                  return (
                    <option
                      key={option.value}
                      selected={pageSize === option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
          <div className="column is-half has-text-right">
            {previousCursors.length > 0 && (
              <button className="button" onClick={onPreviousClicked}>
                Previous
              </button>
            )}
            {listData.hasNextPage && (
              <button className="button" onClick={onNextClicked}>
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminTipsListDesktop;
