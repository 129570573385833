import {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faArrowLeft,
	faGauge,
	faPencil,
	faEye,
	faBolt,
	faPencilRuler,
	faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState, useRecoilValue} from "recoil";
import {useParams} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";

import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	fitnessChallengeDatumState,
} from "../../../AppState";
import {
	deletefitnessChallengeAPI,
	getfitnessChallengeDetailAPI,
} from "../../../API/FitnessChallenge";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import {
	BADGE_CRITERIA_OPTIONS,
	BADGE_STATUS_MAP,
} from "../../../Constants/FieldOptions";
import {putfitnessChallengeAssignBadgeAPI} from "../../../API/AssignBadges";
import ToggleSwitch from "../../Reusable/ToggleSwitch";
import Layout from "../../Menu/Layout";

function AdminFitnessChallengeAssignBadges() {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [selectedUsers, setSelectedUsers] = useState([]); // Track selected users
	const [isToggled, setIsToggled] = useState(false);

	const queryClient = useQueryClient(); // Access the query client

	// Breadcrumb items
	const breadcrumbItems = {
		items: [
			{
				text: "Dashboard",
				link: "/admin/dashboard",
				isActive: false,
				icon: faGauge,
			},
			{
				text: "Challenges",
				link: "/admin/fitness-challenge",
				isActive: false,
				icon: faBolt,
			},
			{text: "Detail", link: "#", isActive: true, icon: faEye},
		],
		mobileBackLinkItems: {
			link: "/fitness-challenge",
			text: "Back to Challenges",
			icon: faArrowLeft,
		},
	};

	////
	//// API.
	////

	// --- Detail --- //

	function onFitnessChallengeDetailSuccess(response) {
		setDatum(response);
		if (response.id) {
			// Filters users with hasEarnedBadge true
			const selectedUserIds =
				response?.users
					?.filter((user) => user.hasEarnedBadge)
					.map((user) => user.id) || [];
			setSelectedUsers(selectedUserIds);
		}
	}

	function onFitnessChallengeDetailError(apiErr) {
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onFitnessChallengeDetailDone() {
		setFetching(false);
	}

	// --- Badge Assignment --- //

	const onBadgeAssignmentSuccess = (response, userId, isAssigned) => {
		if (response.success) {
			setTopAlertMessage(
				`Badge has been ${isAssigned ? "assigned" : "unassigned"} successfully.`
			);
			setTopAlertStatus("success");

			setIsToggled(!isToggled);

			queryClient.invalidateQueries(["accountDetail"]);

			// Update the local state to reflect the new badge status
			setDatum((prevDatum) => ({
				...prevDatum,
				users: prevDatum.users.map((user) =>
					user.id === userId ? {...user, completed: isAssigned} : user
				),
			}));
		}
	};

	const onBadgeAssignmentError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertMessage("Error updating badge status.");
		setTopAlertStatus("danger");
	};

	const onBadgeAssignmentDone = () => {
		// Stop loading indicators, clean up state
		setFetching(false);
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
	};

	////
	//// Event handling.
	////
	const handleToggleBadge = (userId, isAssigned) => {
		setFetching(true); // Start loading indicator

		// Update the selectedUsers array based on the toggle
		let updatedSelectedUsers = [];
		if (isAssigned) {
			// Add user to selected users if assigned
			updatedSelectedUsers = [...selectedUsers, userId];
		} else {
			// Remove user from selected users if unassigned
			updatedSelectedUsers = selectedUsers.filter((id) => id !== userId);
		}

		// Set the new selected users list in the state
		setSelectedUsers(updatedSelectedUsers);

		if (datum) {
			const requestData = {
				id: id,
				badge_id: datum.badgeId, // Use the current badge ID
				users: updatedSelectedUsers, // Send the updated list of selected users
			};

			putfitnessChallengeAssignBadgeAPI(
				requestData,
				onBadgeAssignmentSuccess,
				onBadgeAssignmentError,
				onBadgeAssignmentDone
			);
		}
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.

			setFetching(true);
			getfitnessChallengeDetailAPI(
				id,
				onFitnessChallengeDetailSuccess,
				onFitnessChallengeDetailError,
				onFitnessChallengeDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);
	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems}>
			{/* Page */}
			<nav class="box">
				{/* Title + Options */}
				{datum && (
					<div class="columns">
						<div class="column">
							<p class="title is-4">
								<FontAwesomeIcon className="fas" icon={faBolt} />
								&nbsp;Challenges
							</p>
						</div>
					</div>
				)}

				{/* Tab Navigation */}
				<div class="tabs is-medium is-size-6-mobile">
					<ul>
						<li>
							<Link to={`/admin/fitness-challenge/${id}`}>
								<strong>Detail</strong>
							</Link>
						</li>
						<li>
							<Link to={`/admin/fitness-challenge/${id}/leaderboard`}>
								<strong>LeaderBoard</strong>
							</Link>
						</li>
						{datum.criteria === 2 && (
							<li class="is-active">
								<Link>
									<strong>Assign Badges</strong>
								</Link>
							</li>
						)}
					</ul>
				</div>

				{/* <p class="pb-4">Please fill out all the required fields before submitting this form.</p> */}

				{isFetching ? (
					<PageLoadingContent displayMessage={"Please wait..."} />
				) : (
					<>
						<FormErrorBox errors={errors} />
						<div className="columns">
							<div className="column ">
								<h2 className="title is-4 mb-3">{datum?.name}</h2>
								{/* Challenge Info */}
								{/* Members Section */}
								<p className="subtitle is-6 mt-3">
									<FontAwesomeIcon className="fas" icon={faUsers} />
									&nbsp;Participants
								</p>
								<div className="content">
									<ul>
										{datum?.users?.map((user, index) => (
											<li key={index} className="user-list-item">
												<div className="columns is-mobile is-vcentered">
													<div className="column is-3-desktop is-8-mobile">
														<p>{user.name}</p>
													</div>
													<div className="column is-3-desktop is-4-mobile">
														<ToggleSwitch
															labelRight="Assign Badge"
															labelLeft="Badge Assigned"
															checked={selectedUsers.includes(user.id)}
															onToggle={() =>
																handleToggleBadge(
																	user.id,
																	!selectedUsers.includes(user.id) // Pass the opposite state to handle toggle
																)
															}
														/>
													</div>
												</div>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</>
				)}
			</nav>
		</Layout>
	);
}

export default AdminFitnessChallengeAssignBadges;
