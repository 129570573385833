import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faGauge,
	faTable,
	faRefresh,
	faFilter,
	faSearch,
	faFilterCircleXmark,
	faBolt,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	videoCollectionsFilterShowState,
	videoCollectionsFilterTemporarySearchTextState,
	videoCollectionsFilterActualSearchTextState,
	videoCollectionsFilterStatusState,
	videoCollectionsFilterVideoTypeState,
	videoCollectionsFilterSortState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import MemberListMobile from "./listMobile";
import MemberListDesktop from "./listDesktop";
import {
	getfitnessChallengeListApi,
	patchFitnessChaleengeParticipation,
} from "../../../API/FitnessChallenge";
import Layout from "../../Menu/Layout";
import FormSelectField from "../../Reusable/FormSelectField";

function FitenessChallengeMemberList() {
	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(
		videoCollectionsFilterShowState
	);
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		videoCollectionsFilterTemporarySearchTextState
	);
	const [actualSearchText, setActualSearchText] = useRecoilState(
		videoCollectionsFilterActualSearchTextState
	);
	const [status, setStatus] = useRecoilState(videoCollectionsFilterStatusState);
	const [videoType, setVideoType] = useRecoilState(
		videoCollectionsFilterVideoTypeState
	);
	const [sort, setSort] = useRecoilState(videoCollectionsFilterSortState);
	const [category, setcategory] = useState("");

	// Component states
	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [previousCursors, setPreviousCursors] = useState([]);
	const [nextCursor, setNextCursor] = useState("");
	const [currentCursor, setCurrentCursor] = useState("");

  const breadcrumbItems = {
    items: [
      {
        text: "Dashboard",
        link: "/dashboard",
        isActive: false,
        icon: faGauge,
      },
      {text: "Fitness Challenge", link: "#", icon: faBolt, isActive: true},
    ],
  };

	// API success handler for fetching the challenge list
	function onFitnessChallengeListSuccess(response) {
		if (response && response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor);
			} else {
				setNextCursor("");
			}
		} else {
			setListData([]);
		}
	}

	// API error handler
	function onFitnessChallengelistError(apiErr) {
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onFitnessChallengeListDone() {
		setFetching(false);
	}

	// Fetch list of challenges
	const fetchList = (cur, limit, keywords, st, vt, sbv) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit);

		if (sbv) {
			const sortArray = sbv.split(",");
			params.set("sort_field", sortArray[0]);
			params.set("sort_order", sortArray[1]);
		}

		if (cur) {
			params.set("cursor", cur);
		}

		params.set("type", vt);

		if (keywords) {
			params.set("search", keywords);
		}
		if (st) {
			params.set("status_list", [st]);
		}
		if (category !== 0) {
			params.set("category", category);
		}

		getfitnessChallengeListApi(
			params,
			onFitnessChallengeListSuccess,
			onFitnessChallengelistError,
			onFitnessChallengeListDone
		);
	};

	const onNextClicked = (e) => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = () => {
		setActualSearchText(temporarySearchText);
	};

	const onClearFilterClick = () => {
		setShowFilter(false);
		setActualSearchText("");
		setTemporarySearchText("");
		setVideoType(0);
		setStatus(0);
		setSort("created,-1");
		setcategory("");
	};

	function onChangeError(apiErr) {
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onDone() {
		fetchList(
			currentCursor,
			pageSize,
			actualSearchText,
			status,
			videoType,
			sort
		);
	}

	function onChangeSuccess(response, challengeId) {
		// Find the relevant challenge from the listData and update its users
		const updatedListData = listData.results.map((datum) => {
			if (datum.id === challengeId) {
				// Check if the current user is in the list
				const isUserParticipating = datum.users.some(
					(user) => user.id === currentUser.id
				);

				if (isUserParticipating) {
					// Remove the user from the users array (leaving the challenge)
					datum.users = datum.users.filter(
						(user) => user.id !== currentUser.id
					);
				} else {
					// Add the user to the users array (joining the challenge)
					datum.users.push({id: currentUser.id, completed: false});
				}
			}
			return datum;
		});

		// Update the local state with the updated challenge data
		setListData({...listData, results: updatedListData});

		// Provide feedback to the user based on whether they joined or left
		const isUserInChallenge = updatedListData
			.find((datum) => datum.id === challengeId)
			.users.some((user) => user.id === currentUser.id);

		if (isUserInChallenge) {
			setTopAlertStatus("success");
			setTopAlertMessage("You have joined the challenge");
		} else {
			setTopAlertStatus("success");
			setTopAlertMessage("You have left the challenge");
		}

		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
	}

	const ChangeParticipationStatus = (id) => {
		// Call the API to update server-side state
		patchFitnessChaleengeParticipation(
			id,
			(response) => onChangeSuccess(response, id), // Pass challenge ID to success handler
			onChangeError,
			onDone
		);
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			window.scrollTo(0, 0);
			fetchList(
				currentCursor,
				pageSize,
				actualSearchText,
				status,
				videoType,
				sort
			);
		}

		return () => {
			mounted = false;
		};
	}, [
		currentCursor,
		pageSize,
		actualSearchText,
		status,
		videoType,
		sort,
		category,
	]);

	return (
		<Layout
			breadcrumbItems={breadcrumbItems}>
			<div className="box">
				<div className="columns">
					<div className="column">
						<h1 className="title is-4">
							<FontAwesomeIcon icon={faBolt} />
							&nbsp;Fitness Challenge
						</h1>
					</div>
					<div className="column has-text-right">
						<button
							onClick={() =>
								fetchList(
									currentCursor,
									pageSize,
									actualSearchText,
									status,
									videoType,
									sort
								)
							}
							className="is-fullwidth-mobile button is-link is-small">
							<FontAwesomeIcon icon={faRefresh} />
							&nbsp;Refresh
						</button>
						&nbsp;
						<button
							onClick={() => setShowFilter(!showFilter)}
							className="is-fullwidth-mobile button is-small is-primary">
							<FontAwesomeIcon icon={faFilter} />
							&nbsp;Filter
						</button>
					</div>
				</div>

				{/* Filter */}
				{showFilter && (
					<div
						className="has-background-white-bis"
						style={{borderRadius: "15px", padding: "20px"}}>
						<div className="columns is-mobile">
							<div className="column is-half">
								<strong>
									<u>
										<FontAwesomeIcon icon={faFilter} />
										&nbsp;Filter
									</u>
								</strong>
							</div>
							<div className="column is-half has-text-right">
								<Link onClick={onClearFilterClick}>
									<FontAwesomeIcon icon={faFilterCircleXmark} />
									&nbsp;Clear Filter
								</Link>
							</div>
						</div>

						{/* Filter options */}
						<div className="columns">
							<div className="column">
								<FormInputFieldWithButton
									label="Search"
									name="temporarySearchText"
									type="text"
									placeholder="Search by name"
									value={temporarySearchText}
									onChange={(e) => setTemporarySearchText(e.target.value)}
									buttonLabel={<FontAwesomeIcon icon={faSearch} />}
									onButtonClick={onSearchButtonClick}
								/>
							</div>
							<div className="column">
								<FormSelectField
									name="category"
									label="Category"
									errorText={errors.category}
									options={[
										{label: "Please choose", value: 0},
										{label: "Biometric Based", value: 1},
										{label: "Self Entry Based", value: 2},
									]}
									selectedValue={category}
									onChange={(e) => setcategory(e.target.value)}
								/>
							</div>
						</div>
					</div>
				)}

				{isFetching ? (
					<PageLoadingContent displayMessage="Please wait..." />
				) : (
					<>
						<FormErrorBox errors={errors} />
						{listData && listData.results.length > 0 ? (
							<div>
								<div className="is-hidden-touch">
									<MemberListDesktop
										listData={listData}
										setPageSize={setPageSize}
										pageSize={pageSize}
										previousCursors={previousCursors}
										onPreviousClicked={onPreviousClicked}
										onNextClicked={onNextClicked}
										onChangeStatus={(id) => ChangeParticipationStatus(id)}
									/>
								</div>
								<div className="is-fullwidth is-hidden-desktop">
									<MemberListMobile
										listData={listData}
										setPageSize={setPageSize}
										pageSize={pageSize}
										previousCursors={previousCursors}
										onPreviousClicked={onPreviousClicked}
										onNextClicked={onNextClicked}
										onChangeStatus={(id) => ChangeParticipationStatus(id)}
									/>
								</div>
							</div>
						) : (
							<section className="hero is-medium has-background-white-ter">
								<div className="hero-body">
									<p className="title">
										<FontAwesomeIcon icon={faTable} />
										&nbsp;No Fitness Challenge
									</p>
									<p className="subtitle">
										No Challenges. Please come back later.
									</p>
								</div>
							</section>
						)}
					</>
				)}

				<div className="columns pt-5">
					<div className="column is-half">
						<Link className="button is-hidden-touch" to="/dashboard">
							<FontAwesomeIcon icon={faArrowLeft} />
							&nbsp;Back to Dashboard
						</Link>
						<Link
							className="button is-fullwidth is-hidden-desktop"
							to="/dashboard">
							<FontAwesomeIcon icon={faArrowLeft} />
							&nbsp;Back to Dashboard
						</Link>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default FitenessChallengeMemberList;
