import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

function AccountLevelInfo({currentLevelDetails, currentLevel}) {
	const progressPercentage =
		(currentLevel.challengesCompleted / currentLevelDetails.numChallenges) *
		100;

	return (
		<div className="level-progress-container">
			<div className="level-progress-card">
				{/* Header with Level Info */}
				<div className="level-info">
					<h2 className="title is-4 has-text-light">
						Level {currentLevelDetails.name}
					</h2>
					<p className="subtitle is-6 has-text-grey-light">
						{currentLevelDetails.description}
					</p>
				</div>

				{/* Horizontal Progress Bar */}
				<div className="progress-bar-container">
					<p className="challenge-text">
						Challenges Completed: {currentLevel.challengesCompleted} /{" "}
						{currentLevelDetails.numChallenges}
					</p>
					<div className="progress-bar">
						<div
							className="progress-bar-fill"
							style={{width: `${progressPercentage}%`}}></div>
					</div>
				</div>

				{/* Continue Button */}
				<div className="buttons">
					<Link className="button" to={`/fitness-challenge`}>
						<FontAwesomeIcon icon={faArrowLeft} />
						&nbsp;Continue
					</Link>
				</div>
			</div>
		</div>
	);
}

export default AccountLevelInfo;
