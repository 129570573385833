import React, {useState, useEffect} from "react";
import {useRecoilState} from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRibbon} from "@fortawesome/free-solid-svg-icons";

const Badges = ({badgeDetails}) => {
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	const [errors, setErrors] = useState(null); // Ensure errors is null by default
	const [isFetching, setFetching] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [currentUser]);

	return (
		<div className="card">
			<div className="card-content">
				<div>
					<p className="title is-6 has-text-centered">Badges Earned</p>
					{isFetching ? (
						<PageLoadingContent displayMessage="Loading..." />
					) : errors ? (
						<FormErrorBox errors={errors} />
					) : badgeDetails && badgeDetails.length > 0 ? (
						<div className="columns is-multiline">
							{badgeDetails.map((badge) => (
								<div
									key={badge.id}
									className="column is-one-quarter-desktop is-one-quarter-tablet is-full-mobile">
									<figure className="image is-32x32 is-responsive">
										<img
											style={{maxHeight: "40px", width: "auto"}}
											src={badge.thumbnailUrl}
											alt={badge.name}
										/>
									</figure>
								</div>
							))}
						</div>
					) : (
						<div className="container is-full">
							<div className="has-background-white-ter">
								<div className="hero-body">
									<p className="subtitle is-7" style={{fontSize: "1rem"}}>
										You haven’t earned any badges yet.
									</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Badges;
